import { Calculator } from './components/Calculator';
import './css/app.css';

function App() {
  return (
    <Calculator />
  );
}

export default App;


