import { ToolTipAction } from "./Tooltip";
import { useTranslation } from "react-i18next";
import { MaskedInput } from "./Inputs/MaskedInput";
import { IEstate } from "../interfaces/IEstate";
import { EstateUtils } from "../utils/EstateUtils";
import { NumberInputField } from "./Inputs/NumberInputField";
import { useEffect, useMemo } from "react";
import { CalculationsUtils } from "../utils/CalculationsUtils";
import { ComponentUtils } from "../utils/ComponentUtils";
import { TextField, Typography, Box } from "@mui/material";
import { visuallyHidden } from '@mui/utils';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

interface IProps {
    index: number;
    estate: IEstate
    estateSellingPrice: number | null,
    estateUnclaimedDepreciationImprovementCosts: number | null;
    estateSellingExpenses: number | null;
    isNaturalPerson: boolean;
    estateConveyanceDate: string;
    onUpdateEstateCost: (index: number, estate: IEstate) => void;
    removeEstateSection?: (index: number) => void;
}

export function EstateCostComponent(props: IProps) {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        // MUI doesn't allow set the field type, which by default is tel at current version
        // Force cange the field type to text, so screen reader read this field as text type
        const calendarInput = document.getElementById(`estate-date-${props.index}`);
        calendarInput?.setAttribute("type", "text");
        calendarInput?.setAttribute("aria-live", "polite");
    }, []);

    useEffect(() => {
        // MUI doesn't allow set the field type, which by default is tel at current version
        // Force cange the field type to text, so screen reader read this field as text type
        const calendarInput = document.getElementById(`estate-date-${props.index}`);
        calendarInput?.setAttribute("placeholder", t("dateFormat"));
    }, [i18n.language])

    const estateCostImprovementExpenses = useMemo(() => {
        const value = EstateUtils.calculateEstateCostImprovementExpenses(props.estate.estatePercentage, props.estateUnclaimedDepreciationImprovementCosts);
        if (value === null) { return ""; }

        return CalculationsUtils.formatNumber(`${value}`, 2);
    }, [props.estate.estatePercentage, props.estateUnclaimedDepreciationImprovementCosts]);

    const priceFromOwnership = useMemo(() => {
        const value = EstateUtils.calculatePriceFromOwnership(props.estate.estatePercentage, props.estateSellingPrice);
        if (value === null) { return ""; }

        return CalculationsUtils.formatNumber(`${value}`, 2);
    }, [props.estate.estatePercentage, props.estateSellingPrice]);

    const totalForestEstateSalesCostsYield = useMemo(() => {
        const value = EstateUtils.calculateTotalForestEstateSalesCostsYield(props.estateSellingExpenses, props.estate.estatePercentage);
        if (value === null) { return ""; }

        return CalculationsUtils.formatNumber(`${value}`, 2);
    }, [props.estateSellingExpenses, props.estate.estatePercentage]);

    const totalEstateCosts = useMemo(() => {
        const value = EstateUtils.calculateTotalEstateCosts(
            props.estate.estatePurchasePrice || 0,
            props.estate.inheritanceTax || 0,
            props.estate.giftTax || 0,
            props.estate.stampDutyTax || 0,
            props.estate.landRegistrationCost || 0,
            props.estate.landSurveyingPrice || 0,
            props.estate.otherestateCosts || 0,
            EstateUtils.calculateEstateCostImprovementExpenses(props.estate.estatePercentage, props.estateUnclaimedDepreciationImprovementCosts) || 0,
            EstateUtils.calculateTotalForestEstateSalesCostsYield(props.estateSellingExpenses, props.estate.estatePercentage) || 0,
        );
        if (value === null) { return ""; }

        return CalculationsUtils.formatNumber(`${value}`, 2);
    }, [props.estate, props.estateUnclaimedDepreciationImprovementCosts, props.estateSellingExpenses]);

    const acquisitionCostAssumption = useMemo(() => {
        const value = EstateUtils.getAcquisitionCostAssumption(props.estate.estateDate, props.estateConveyanceDate, props.isNaturalPerson, props.estate.estatePercentage, props.estateSellingPrice);
        if (value === null) { return ""; }

        return CalculationsUtils.formatNumber(`${value}`, 2);
    }, [props.estate.estateDate, props.estateConveyanceDate, props.isNaturalPerson, props.estate.estatePercentage, props.estateSellingPrice]);



    function getEstateIndexToText(index: number): number {
        return index + 1;
    }

    function updateState(updatedObject: Partial<IEstate>): void {
        const updatedState: IEstate = { ...props.estate, ...updatedObject };
        props.onUpdateEstateCost(props.index, updatedState);
    }

    function calculateEstateFractionNumbers(fractionString: string): void {
        const percentage: number | null = EstateUtils.getEstatePercentageFromFraction(fractionString);
        updateState({ "estateFraction": fractionString, "estatePercentage": percentage });
    }

    function onBlurEvent(event: any, key?: string, callback?: (eventValue: string) => void) {
        if (event.target.value === "" && key) {
            let updatedState: any = {};
            updatedState[key] = 0;

            updateState(updatedState);
            callback?.(`0`);
        } else {
            callback?.(event.target.value);
        }
    }

    function onFocusEvent(event: any) {
        if (event.target.value === "0.00") {
            event.target.select();
        }
    }

    function maskedInputOnInvalid(id: string) {
        const parentRowNode = document.getElementById(id)?.parentElement?.parentElement?.parentElement || undefined;
        ComponentUtils.formInputFieldInvalidNotification(`estate-percentage-acquire-${props.index}`, t("invalidEstatePercentageAcquiredInput"), parentRowNode);
    }

    return (
        <div className="estateInfoContainer">
            <div className="sectionContainer">
                <div className="sectionHeading inlineHeading">
                    <h3 tabIndex={0}>{t("estateHeader").replace("{$estate}", `${getEstateIndexToText(props.index)}.`)}</h3>
                    {props.removeEstateSection && (
                        <button className="baseButton pull-right dangerButton" onClick={() => props.removeEstateSection?.(props.index)} tabIndex={0}>
                            {t("remove")}
                        </button>
                    )}

                </div>
                <div className="section">
                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <input aria-label={`${t("estateType")}`} id={`estate-type-${props.index}`} data-testid={`data-test-id-estate-acquisition-way-${props.index}`} type="text" maxLength={50} onChange={(event) => updateState({ "estateType": event.target.value })} value={props.estate.estateType} required onInvalid={() => ComponentUtils.formInputFieldInvalidNotification(`estate-type-${props.index}`, t("invalidEstateType"))} />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("estateTypeTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`estate-type-${props.index}`}>{t("estateType")}</label>
                            </div>
                        </div>

                        <div className="rowElement">
                            <div className="rowInputElement">
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                                    <Box id={`estate-date-container-${props.index}`}>
                                        <DatePicker
                                            inputFormat="DD.MM.YYYY"
                                            value={props.estate.estateDate || null}
                                            onChange={(newValue) => {
                                                if (newValue) {
                                                    updateState({ "estateDate": dayjs(newValue).format('YYYY-MM-DD') })
                                                } else {
                                                    updateState({ "estateDate": "" })
                                                }
                                            }}
                                            InputProps={{
                                                "aria-label": `${t("estateDate")}`,
                                                className: "calendarInput",
                                                required: true,

                                            }}
                                            renderInput={(params) => <TextField variant="outlined" id={`estate-date-${props.index}`} data-testid={`data-test-id-estate-date-${props.index}`} {...params} onInvalid={() => ComponentUtils.formInputFieldInvalidNotification(`estate-date-container-${props.index}`, t("invalidEstateDate"))} />}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("estateDateTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`estate-date-${props.index}`}>{t("estateDate")}</label>
                            </div>
                        </div>

                        <div className="rowElement">
                            <div className="rowInputElement">
                                <MaskedInput ariaLabel={t("estatePercentageAcquiredInput")} id={`estate-percentage-acquire-${props.index}`} onupdate={(updatedValue: string) => calculateEstateFractionNumbers(updatedValue)} inputValue={props.estate.estateFraction} index={props.index} onInvalid={() => maskedInputOnInvalid(`estate-percentage-acquire-${props.index}-estatePercentageOwnership`)} />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("estatePercentageAcquiredInputTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`estate-percentage-acquire-${props.index}`}>{t("estatePercentageAcquiredInput")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={`data-test-id-estate-percentage-${props.index}`} aria-hidden>{props.estate.estatePercentage?.toFixed(2) ?? ""}</span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("estatePercentageSold")}.</Typography>
                                            <Typography>{t("estatePercentageSoldTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{props.estate.estatePercentage ? props.estate.estatePercentage?.toFixed(2) + "%" : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("estatePercentageSold")}</span>
                            </div>
                        </div>

                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={`data-test-id-estate-price-from-percentage-${props.index}`} aria-hidden>{priceFromOwnership}</span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("estatePercentFromPrice")}</Typography>
                                            <Typography>{t("estatePercentFromPriceTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{priceFromOwnership ? priceFromOwnership : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("estatePercentFromPrice")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sectionContainer mt-40">
                <div className="sectionHeading">
                    <h4>{t("estateCostOfTheYieldHeader")}</h4>
                </div>
                <div className="section">
                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("estatePurchasePrice")}
                                    formatByDefault={true}
                                    precision={2}
                                    stateKey="estatePurchasePrice"
                                    onBlur={onBlurEvent}
                                    onFocus={onFocusEvent}
                                    dataTestid={`data-test-id-estate-purchase-price-${props.index}`}
                                    value={props.estate.estatePurchasePrice}
                                    onUpdateState={(value) => updateState({ "estatePurchasePrice": EstateUtils.parseNumber(value) })}
                                    id={`estate-purchase-price-${props.index}`}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("estatePurchasePriceTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`estate-purchase-price-${props.index}`}>{t("estatePurchasePrice")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("inheritanceTaxValue")}
                                    formatByDefault={true}
                                    precision={2}
                                    stateKey="inheritanceTax"
                                    onBlur={onBlurEvent}
                                    onFocus={onFocusEvent}
                                    dataTestid={`data-test-id-inheritance-tax-${props.index}`}
                                    value={props.estate.inheritanceTax}
                                    onUpdateState={(value) => updateState({ "inheritanceTax": EstateUtils.parseNumber(value) })}
                                    id={`inheritance-tax-value-${props.index}`}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("inheritanceTaxValueTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`inheritance-tax-value-${props.index}`}>{t("inheritanceTaxValue")}</label>
                            </div>
                        </div>
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("giftTaxValue")}
                                    formatByDefault={true}
                                    precision={2}
                                    stateKey="giftTax"
                                    onBlur={onBlurEvent}
                                    onFocus={onFocusEvent}
                                    dataTestid={`data-test-id-gift-tax-${props.index}`}
                                    value={props.estate.giftTax}
                                    onUpdateState={(value) => updateState({ "giftTax": EstateUtils.parseNumber(value) })}
                                    id={`gift-tax-value-${props.index}`}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("giftTaxValueTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`gift-tax-value-${props.index}`}>{t("giftTaxValue")}</label>
                            </div>
                        </div>
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("stampDuty")}
                                    formatByDefault={true}
                                    precision={2}
                                    stateKey="stampDutyTax"
                                    onBlur={onBlurEvent}
                                    onFocus={onFocusEvent}
                                    dataTestid={`data-test-id-stamp-duty-tax-${props.index}`}
                                    value={props.estate.stampDutyTax}
                                    onUpdateState={(value) => updateState({ "stampDutyTax": EstateUtils.parseNumber(value) })}
                                    id={`stamp-duty-${props.index}`}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("stampDutyTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`stamp-duty-${props.index}`}>{t("stampDuty")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="rowElement" style={{ marginRight: 10 }}>
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("landRegistration")}
                                    formatByDefault={true}
                                    precision={2}
                                    stateKey="landRegistrationCost"
                                    onBlur={onBlurEvent}
                                    onFocus={onFocusEvent}
                                    dataTestid={`data-test-id-land-registration-cost-${props.index}`}
                                    value={props.estate.landRegistrationCost}
                                    onUpdateState={(value) => updateState({ "landRegistrationCost": EstateUtils.parseNumber(value) })}
                                    id={`land-registration-${props.index}`}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("landRegistrationTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`land-registration-${props.index}`}>{t("landRegistration")}</label>
                            </div>
                        </div>
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("landSurveying")}
                                    formatByDefault={true}
                                    precision={2}
                                    stateKey="landSurveyingPrice"
                                    onBlur={onBlurEvent}
                                    onFocus={onFocusEvent}
                                    dataTestid={`data-test-id-land-surveying-price-${props.index}`}
                                    value={props.estate.landSurveyingPrice}
                                    onUpdateState={(value) => updateState({ "landSurveyingPrice": EstateUtils.parseNumber(value) })}
                                    id={`land-surveying-${props.index}`}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("landSurveyingTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`land-surveying-${props.index}`}>{t("landSurveying")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("otherestateCosts")}
                                    formatByDefault={true}
                                    precision={2}
                                    stateKey="otherestateCosts"
                                    onBlur={onBlurEvent}
                                    onFocus={onFocusEvent}
                                    dataTestid={`data-test-id-other-estate-costs-${props.index}`}
                                    value={props.estate.otherestateCosts}
                                    onUpdateState={(value) => updateState({ "otherestateCosts": EstateUtils.parseNumber(value) })}
                                    id={`other-estate-costs-${props.index}`}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("otherestateCostsTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`other-estate-costs-${props.index}`}>{t("otherestateCosts")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="rowElement">
                            <div className="rowElementHeader">
                                <div className="rowElementHeaderInputElement">
                                    <input aria-label={`${t("estateCostAssumptionInTheCalculationCheckbox")}`} id={`estate-cost-assumption-in-the-calculation-${props.index}`} type="checkbox" checked={props.estate.estateCostAssumption} onChange={(event) => updateState({ estateCostAssumption: event.target.checked })} />
                                </div>
                                <ToolTipAction
                                    text={
                                        <Typography>{t("estateCostAssumptionInTheCalculationCheckboxTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor={`estate-cost-assumption-in-the-calculation-${props.index}`}>{t("estateCostAssumptionInTheCalculationCheckbox")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={`data-test-id-estate-cost-improvement-expenses-${props.index}`} aria-hidden>{estateCostImprovementExpenses}</span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("estateCostImprovementExpenses")}.</Typography>
                                            <Typography>{t("estateCostImprovementExpensesTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{estateCostImprovementExpenses ? t("calculatedValue").replace("{$value}", estateCostImprovementExpenses) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("estateCostImprovementExpenses")}</span>
                            </div>
                        </div>

                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={`data-test-id-forest-estate-sales-costs-yield-${props.index}`} aria-hidden>{totalForestEstateSalesCostsYield}</span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("forestEstateSalesCostsYield")}.</Typography>
                                            <Typography>{t("forestEstateSalesCostsYieldTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{totalForestEstateSalesCostsYield ? t("calculatedValue").replace("{$value}", totalForestEstateSalesCostsYield) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("forestEstateSalesCostsYield")}</span>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={`data-test-id-actual-estate-cost-${props.index}`} aria-hidden>{totalEstateCosts}
                                </span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("actualestateCost").replace("{$estate}", `${getEstateIndexToText(props.index)}.`)}</Typography>
                                            <Typography>{t("actualestateCostTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{totalEstateCosts ? t("calculatedValue").replace("{$value}", totalEstateCosts) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("actualestateCost").replace("{$estate}", `${getEstateIndexToText(props.index)}.`)}</span>
                            </div>
                        </div>
                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={`data-test-id-estate-cost-assumption-${props.index}`} aria-hidden>{acquisitionCostAssumption}</span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("estateCostAssumption").replace("{$estate}", `${getEstateIndexToText(props.index)}.`)}</Typography>
                                            <Typography>{t("estateCostAssumptionTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{acquisitionCostAssumption ? t("calculatedValue").replace("{$value}", acquisitionCostAssumption) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("estateCostAssumption").replace("{$estate}", `${getEstateIndexToText(props.index)}.`)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}