import { IEstateInfo } from "../interfaces/IEstate"

export const initialEstateInfo: IEstateInfo = {
    estateNameAndId: "",
    estateHectareArea: null,
    estateConveyanceDate: "",
    estateSellingPrice: null,
    estateSellingExpenses: null,
    estateUnclaimedDepreciationImprovementCosts: null,
    nonNaturalPerson: false
}