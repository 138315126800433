import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICalculator } from "../interfaces/ICalculator";
import { IEstate } from "../interfaces/IEstate";
import { initialEstate } from "../models/MEstate";
import { EstateCostComponent } from "./EstateCostComponent";

interface IProps {
    onUpdateState: (updatedState: IEstate[], key: keyof ICalculator) => void;
    estateUnclaimedDepreciationImprovementCosts: number | null;
    estateSellingExpenses: number | null;
    estateSellingPrice: number | null;
    isNaturalPerson: boolean;
    estateConveyanceDate: string;
}

export const EstateCostInfoComponent = forwardRef((props: IProps, ref) => {
    const { t } = useTranslation();
    const [estates, setEstates] = useState<IEstate[]>([initialEstate]);

    useImperativeHandle(ref, () => ({
        notifyEstateUpdates(updatedEstates: IEstate[]) {
            setEstates(updatedEstates);
        }
    }));

    useEffect(() => {
        props.onUpdateState(estates, "estateCostInfo");
    }, [estates]);

    function onUpdateEstateCost(index: number, updatedEstate: IEstate): void {
        const updatedEstates = [...estates];

        if (updatedEstates[index]) {
            updatedEstates[index] = updatedEstate;
        }

        setEstates(updatedEstates);
    }

    function addNewEstateInfo(): void {
        setEstates([...estates, initialEstate]);

        try {
            setTimeout(() => {
                const estateInfoContainers = document.querySelectorAll(`div.estateInfoContainer div.sectionHeading h3`);
                // @ts-ignore
                estateInfoContainers[estateInfoContainers.length - 1].focus()
            }, 500);

        } catch (error) { }
    }

    function removeEstateInfo(index: number): void {
        const updatedEstates = [...estates];
        updatedEstates.splice(index, 1);
        setEstates(updatedEstates);
    }

    return (
        <div className="sectionContainer">
            <div className="sectionHeading">
                <h2>{t("estateCostInfoHeader")}</h2>
            </div>
            {estates.map((estate, index) =>
                <EstateCostComponent
                    key={index}
                    index={index}
                    estate={estate}
                    onUpdateEstateCost={onUpdateEstateCost}
                    removeEstateSection={estates.length > 1 ? removeEstateInfo : undefined}
                    estateUnclaimedDepreciationImprovementCosts={props.estateUnclaimedDepreciationImprovementCosts}
                    estateSellingPrice={props.estateSellingPrice}
                    estateSellingExpenses={props.estateSellingExpenses}
                    isNaturalPerson={props.isNaturalPerson}
                    estateConveyanceDate={props.estateConveyanceDate}
                />
            )}
            <button data-testid={`data-test-id-add-estate-button`} className="baseButton pull-right sectionContainerButton" type="button" onClick={addNewEstateInfo}>{t("estateAddButton")}</button>
        </div>
    )
})