import { IAttachment, IFooter, IPDF, ISection } from "../interfaces/IPdfReport";
import { Buffer } from 'buffer';
import { RUBIK_FONT, XMP_METADATA } from "./PdfMetadataService";

const blobStream = require('../externalModules/blobStream');

export function generatePdfDocument(title: string, language: string, documentData: IPDF): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
        try {
            // PDFKit comes from <script> link in html, There isn't lib which support npm atm
            // @ts-ignore
            const doc = new PDFDocument({ size: 'A4', pdfVersion: "1.7", info: { Title: title }, lang: language, tagged: true, displayTitle: true, margins: { bottom: 0 } });
            addMetadata(doc, title);
            doc.font("Rubik");

            const stream = doc.pipe(blobStream());
            generateSummaryPage(doc, documentData.summaryPageData);
            generateFooter(doc, documentData.footerData);

            documentData.attachments.forEach(async (attachment: IAttachment) => {
                generateEstateSummaryPage(doc, attachment, language);
                generateFooter(doc, documentData.footerData);
            })

            // get a blob when you're done
            doc.end();

            stream.on('finish', function () {
                // get a blob you can do whatever you like with
                const blob = stream.toBlob('application/pdf');
                return resolve(blob);
            });
        } catch (error) {
            console.log(error);
            return reject();
        }
    });
}

function addMetadata(doc: any, title: string): void {
    // Embedded font into pdf metadata
    doc.registerFont("Rubik", Buffer.from(RUBIK_FONT, "base64"));

    // Metadata defines document type.
    const metadata = XMP_METADATA.replace("$PDF_TITLE", title).trim();
    const refMetadata = doc.ref({
        Length: metadata.length,
        Type: 'Metadata',
        Subtype: 'XML',
    });

    refMetadata.compress = false;

    refMetadata.write(Buffer.from(metadata, 'utf-8'));
    refMetadata.end();

    // Add manually created objects to catalog.
    doc._root.data.Metadata = refMetadata;
}

function generateSummaryPage(doc: any, sections: ISection[]): void {
    sections.forEach(section => {
        // Add label
        doc.addStructure(doc.struct(section.element || 'H2', [
            () => { doc.fillColor("#167D3F").fontSize(section.element && section.element == "H1" ? 18 : 16).text(section.title, section.titleTextProperties.x, section.titleTextProperties.y) }
        ]
        ));

        section.data.forEach((pageData, index) => {
            const padding = 10;

            //Draw box
            doc.markContent('Artifact', { type: "Layout" });
            doc.fillColor('#167D3F').rect(pageData.labelTextProperties.x - padding, pageData.labelTextProperties.y - padding, pageData.labelTextProperties.width + padding, pageData.labelTextProperties.height).fill();
            doc.markContent('Artifact', { type: "Layout" });
            doc.fillColor(index % 2 === 0 ? "#FFFAFA" : "#FFF").rect(pageData.valueTextProperties.x, pageData.valueTextProperties.y - padding, pageData.valueTextProperties.width, pageData.valueTextProperties.height).fill();

            // Add texts
            doc.addStructure(doc.struct('P', [
                () => { doc.fillColor("#FFF").fontSize(11).text(`${pageData.label} `, pageData.labelTextProperties.x, pageData.labelTextProperties.y, { width: pageData.labelTextProperties.width, height: pageData.labelTextProperties.height }) },
                () => {
                    doc.fillColor("#000")
                        .fontSize(10)
                        .text(
                            `${pageData.value} `, pageData.valueTextProperties.x + (pageData.valueTextProperties.textXMargin || 0),
                            pageData.valueTextProperties.y + (pageData.valueTextProperties.textYMargin || 0),
                            { width: pageData.valueTextProperties.width, height: pageData.valueTextProperties.height, align: pageData.valueTextProperties.align }
                        )
                }
            ]))
        });
    });
}


function generateFooter(doc: any, footerData: IFooter): void {
    doc.addStructure(doc.struct('P', [
        () => { doc.fillColor("#000").fontSize(7).text(footerData.time, 15, 820, { width: 70 }) },
        () => { doc.fillColor("#000").fontSize(5).text(footerData.disclaimer.text, footerData.disclaimer.properties.x, footerData.disclaimer.properties.y, { width: 320 }) }
    ]));

    let padding = 58;

    doc.markContent('Artifact', { type: "Layout" });

    doc.addStructure(doc.struct('Sect', {
        alt: 'Metsäkeskus logo'
    }, [
        () => doc.image(decodeURIComponent(escape(footerData.logos[0])), 413, 814, { width: 50 })
    ]));

    doc.markContent('Artifact', { type: "Layout" });
    doc.addStructure(doc.struct('Sect', {
        alt: 'Bitcomp logo'
    }, [
        () => doc.image(decodeURIComponent(escape(footerData.logos[1])), 413 + padding, 815, { width: 50 })
    ]));
    doc.markContent('Artifact', { type: "Layout" });
    doc.addStructure(doc.struct('Sect', {
        alt: 'Tapio logo'
    }, [
        () => doc.image(decodeURIComponent(escape(footerData.logos[2])), 413 + (padding * 2), 815, { width: 40 })
    ]));
}

function generateEstateSummaryPage(doc: any, attachment: IAttachment, language: string): void {
    doc.addPage({ size: 'A4', pdfVersion: "1.7", lang: language, tagged: true, displayTitle: true, margins: { bottom: 0 } });

    // Add label
    doc.addStructure(doc.struct('H2', [
        () => { doc.fillColor("#167D3F").fontSize(16).text(attachment.title, 35, 45) }
    ]
    ));

    const tableCellWidthPadding = 7;
    attachment.table.forEach(cell => {
        //Draw box
        doc.markContent('Artifact', { type: "Layout" });
        doc.fillColor('#167D3F').rect(cell.labelTextProperties.x - tableCellWidthPadding, cell.labelTextProperties.y + tableCellWidthPadding, cell.labelTextProperties.width + tableCellWidthPadding, cell.labelTextProperties.height + tableCellWidthPadding).fill();
        doc.markContent('Artifact', { type: "Layout" });
        doc.fillColor("#FFFAFA").rect(cell.valueTextProperties.x, cell.valueTextProperties.y, cell.valueTextProperties.width + tableCellWidthPadding, cell.valueTextProperties.height).fill();


        // Add texts
        doc.addStructure(doc.struct('P', [
            () => {
                doc.fillColor("#FFF").
                    fontSize(10).
                    text(
                        cell.label,
                        cell.labelTextProperties.x + (cell.valueTextProperties.textXMargin || 0),
                        cell.labelTextProperties.y + (cell.valueTextProperties.textYMargin || 0),
                        { width: cell.labelTextProperties.width, height: cell.labelTextProperties.height, align: cell.valueTextProperties.align })
            },
            () => {
                doc.fillColor("#000")
                    .fontSize(10)
                    .text(
                        cell.value, cell.valueTextProperties.x + (cell.valueTextProperties.textXMargin || 0),
                        cell.valueTextProperties.y + (cell.valueTextProperties.textYMargin || 0),
                        { width: cell.valueTextProperties.width, height: cell.valueTextProperties.height, align: cell.valueTextProperties.align }
                    )
            }
        ]));
    })

    attachment.section.forEach(section => {
        // Add label
        doc.addStructure(doc.struct(section.element || 'H3', [
            () => { doc.fillColor("#167D3F").fontSize(16).text(section.title, section.titleTextProperties.x, section.titleTextProperties.y) }
        ]
        ));

        section.data.forEach((pageData, index) => {
            const padding = 10;

            //Draw box
            doc.markContent('Artifact', { type: "Layout" });
            doc.fillColor('#167D3F').rect(pageData.labelTextProperties.x - padding, pageData.labelTextProperties.y - padding, pageData.labelTextProperties.width + padding, pageData.labelTextProperties.height).fill();
            doc.markContent('Artifact', { type: "Layout" });
            doc.fillColor(index % 2 === 0 ? "#FFFAFA" : "#FFF").rect(pageData.valueTextProperties.x, pageData.valueTextProperties.y - padding, pageData.valueTextProperties.width, pageData.valueTextProperties.height).fill();

            // Add texts
            doc.addStructure(doc.struct('P', [
                () => { doc.fillColor("#FFF").fontSize(11).text(`${pageData.label} `, pageData.labelTextProperties.x, pageData.labelTextProperties.y, { width: pageData.labelTextProperties.width, height: pageData.labelTextProperties.height }) },
                () => {
                    doc.fillColor("#000")
                        .fontSize(10)
                        .text(
                            `${pageData.value} `, pageData.valueTextProperties.x + (pageData.valueTextProperties.textXMargin || 0),
                            pageData.valueTextProperties.y + (pageData.valueTextProperties.textYMargin || 0),
                            { width: pageData.valueTextProperties.width, height: pageData.valueTextProperties.height, align: pageData.valueTextProperties.align }
                        )
                }
            ]))
        });
    });
}