import { useCallback, useMemo } from "react";
import { ICalculator } from "../interfaces/ICalculator";
import { IEstate, IEstateInfo } from "../interfaces/IEstate";
import { CalculationsUtils } from "../utils/CalculationsUtils";
import { EstateUtils } from "../utils/EstateUtils";

interface IProps {
    state?: ICalculator;
}

export function useCalculations(props: IProps): any[] {
    const getTotalPrice = useMemo(() => {
        if (props.state) {
            return CalculationsUtils.totalSalesPriceCalculation(props.state.estateInfo.estateSellingPrice || 0, props.state.estateDamages.damages || 0)
        }
        return 0;
    }, [props.state]);

    const getTotalEstateAcquisitionCosts = useMemo(() => {
        if (props.state) {
            return CalculationsUtils.totalAcquisitionOfForestProperty(
                props.state.estateCostInfo,
                props.state.estateInfo.estateUnclaimedDepreciationImprovementCosts || 0,
                props.state.estateInfo.estateSellingExpenses || 0, props.state.estateInfo.estateSellingPrice || 0, props.state.estateInfo.estateConveyanceDate, !props.state.estateInfo.nonNaturalPerson
            )
        }
        return 0;
    }, [props.state]);

    const getTotalProfitsOrLosses = useMemo(() => {
        if (props.state) {
            return getTotalPrice - getTotalEstateAcquisitionCosts
        }
        return 0;
    }, [props.state]);

    const getTotalProfitsOrLossesWithForestReduction = useMemo(() => {
        if (props.state) {
            return getTotalProfitsOrLosses + CalculationsUtils.calculatedTotalForestBaseReduction(props.state.estateForestReduction.sellerTotalForestReduction || 0, CalculationsUtils.totalForestBaseReduction(props.state.estateCostInfo));
        }
        return 0;
    }, [props.state]);

    const totalTaxMin = useMemo(() => {
        if (props.state) {
            if (getTotalProfitsOrLossesWithForestReduction <= 0) {
                return 0;
            }

            // Rest of the tax which goes over 30k
            const restOfTheTax = Math.max((getTotalProfitsOrLossesWithForestReduction - 30000), 0);
            return parseFloat(((getTotalProfitsOrLossesWithForestReduction * 0.3) + restOfTheTax * 0.04).toFixed(2));
        }
        return 0;
    }, [props.state]);

    const totalTaxMax = useMemo(() => {
        if (props.state) {
            if (getTotalProfitsOrLossesWithForestReduction <= 0) {
                return 0;
            }

            return parseFloat(((getTotalProfitsOrLossesWithForestReduction * 0.34).toFixed(2)));
        }
        return 0;
    }, [props.state]);

    const calculatedTotalForestBaseReduction = useMemo(() => {
        if (props.state) {
            return parseFloat(CalculationsUtils.calculatedTotalForestBaseReduction(props.state.estateForestReduction.sellerTotalForestReduction || 0, CalculationsUtils.totalForestBaseReduction(props.state.estateCostInfo)).toFixed(2));
        }
        return 0;
    }, [props.state]);

    const calculateTotalForestBaseReductionWithoutLimit = useMemo(() => {
        if (props.state) {
            return CalculationsUtils.totalForestBaseReduction(props.state.estateCostInfo);
        }
        return 0;
    }, [props.state]);

    const calculateEstateCostImprovementExpenses = useCallback((estate: IEstate, estateUnclaimedDepreciationImprovementCosts: number) => {
        return EstateUtils.calculateEstateCostImprovementExpenses(estate.estatePercentage, estateUnclaimedDepreciationImprovementCosts) || 0
    }, [props.state]);

    const getTotalForestEstateSalesCostsYield = useCallback((estateSellingExpenses: number, estatePercentage: number) => {
        const value = EstateUtils.calculateTotalForestEstateSalesCostsYield(estateSellingExpenses, estatePercentage);
        if (value === null) { return ""; }

        return value;
    }, [props.state]);

    const getTotalEstateCosts = useCallback((estate: IEstate, estateSellingExpenses: number, estateUnclaimedDepreciationImprovementCosts: number) => {
        const value = EstateUtils.calculateTotalEstateCosts(
            estate.estatePurchasePrice || 0,
            estate.inheritanceTax || 0,
            estate.giftTax || 0,
            estate.stampDutyTax || 0,
            estate.landRegistrationCost || 0,
            estate.landSurveyingPrice || 0,
            estate.otherestateCosts || 0,
            EstateUtils.calculateEstateCostImprovementExpenses(estate.estatePercentage, estateUnclaimedDepreciationImprovementCosts) || 0,
            EstateUtils.calculateTotalForestEstateSalesCostsYield(estateSellingExpenses, estate.estatePercentage) || 0,
        );
        if (value === null) { return ""; }

        return value;
    }, [props.state]);

    const getCalculatePriceFromOwnership = useCallback((estate: IEstate, estateSellingPrice: number) => {
        return EstateUtils.calculatePriceFromOwnership(estate.estatePercentage, estateSellingPrice) || 0;
    }, [props.state]);

    const getAcquisitionCostAssumption = useCallback((estate: IEstate, estateInfo: IEstateInfo) => {
        const value = EstateUtils.getAcquisitionCostAssumption(estate.estateDate, estateInfo.estateConveyanceDate, !estateInfo.nonNaturalPerson, estate.estatePercentage, estateInfo.estateSellingPrice);
        if (value === null) { return ""; }

        return value;
    }, [props.state]);

    return [
        getTotalPrice,
        getTotalEstateAcquisitionCosts,
        getTotalProfitsOrLosses,
        getTotalProfitsOrLossesWithForestReduction,
        totalTaxMin,
        totalTaxMax,
        calculatedTotalForestBaseReduction,
        calculateTotalForestBaseReductionWithoutLimit,
        calculateEstateCostImprovementExpenses,
        getTotalForestEstateSalesCostsYield,
        getTotalEstateCosts,
        getCalculatePriceFromOwnership,
        getAcquisitionCostAssumption
    ];
}