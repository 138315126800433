import { IEstate } from "../interfaces/IEstate";
import { EstateUtils } from "./EstateUtils";

export class CalculationsUtils {
    public static totalSalesPriceCalculation(salesPrice: number, compensation: number): number {
        return salesPrice + compensation;
    }

    public static totalAcquisitionOfForestProperty(estate: IEstate[], estateUnclaimedDepreciationImprovementCosts: number, estateSellingExpenses: number, estateSellingPrice: number, estateConveyanceDate: string, isNaturalPerson: boolean): number {
        return estate.reduce((total: number, property: IEstate) => {
            let sum = 0;
            if (!property.estateCostAssumption) {
                sum = EstateUtils.calculateTotalEstateCosts(
                    property.estatePurchasePrice || 0,
                    property.inheritanceTax || 0,
                    property.giftTax || 0,
                    property.stampDutyTax || 0,
                    property.landRegistrationCost || 0,
                    property.landSurveyingPrice || 0,
                    property.otherestateCosts || 0,
                    EstateUtils.calculateEstateCostImprovementExpenses(property.estatePercentage, estateUnclaimedDepreciationImprovementCosts) || 0,
                    EstateUtils.calculateTotalForestEstateSalesCostsYield(estateSellingExpenses, property.estatePercentage) || 0,
                )
            } else {
                sum = EstateUtils.getAcquisitionCostAssumption(property.estateDate, estateConveyanceDate, isNaturalPerson, property.estatePercentage, estateSellingPrice) || 0
            }

            return total + sum
        }, 0);
    }

    public static totalForestBaseReduction(estate: IEstate[]): number {
        return estate.reduce((total: number, property: IEstate) => {
            const forestBaseReduction = property.forestBaseReduction || 0;
            return total + forestBaseReduction;
        }, 0);
    }

    // TotalForestBaseReduction cannot be over the given sellerTotalForestReductionInput
    public static calculatedTotalForestBaseReduction(sellerTotalForestReductionInput: number, calculatedTotalForestBaseReduction: number): number {
        if (sellerTotalForestReductionInput > calculatedTotalForestBaseReduction) {
            return calculatedTotalForestBaseReduction;
        }

        return sellerTotalForestReductionInput;
    }

    public static formatNumber(value: string, precision: number = 2): string {
        const parsedValue = EstateUtils.parseNumber(value, precision);

        // Decimal point is dot and thousands seperator is comma which is en format
        const formatedValue = parsedValue !== null ? Intl.NumberFormat('fi-FI', {
            style: 'currency', currency: 'EUR'
        }).format(parsedValue) : parsedValue;

        return `${formatedValue}`
    }
}