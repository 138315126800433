export class EstateUtils {
    // Acquisition cost assumption percentages
    public static readonly ACAPercentages = {
        normal: { percentage: 0.2 },
        higher: { isValidYearRequirements: (endDate: string, startDate: string) => EstateUtils.dateDifferenceIsGreaterThanYears(endDate, startDate, 10), percentage: 0.4 },
        nonNaturalPerson: { percentage: 0.8 }
    }

    public static getEstatePercentageFromFraction(fraction: string): number | null {
        try {
            const numerator = parseInt(fraction.substring(0, fraction.indexOf("/")));
            const denominator = parseInt(fraction.substring(fraction.lastIndexOf("/") + 1, fraction.length));
            if (numerator && denominator) {
                return (numerator / denominator) * 100;
            }
        } catch (error) {
            console.error(`EstateUtils:getEstatePercentageFromFraction; ex=${error}`);
        }

        return null;
    }

    public static calculatePriceFromOwnership(percentage: number | null, totalPrice: number | null): number | null {
        try {
            if (!percentage || !totalPrice) { return null; }

            const decimalPercentage = (percentage / 100);
            return parseFloat(Math.round((totalPrice * decimalPercentage)).toFixed(2));
        } catch (error) {
            console.error(`EstateUtils:calculatePriceFromOwnership; ex=${error}`);
        }

        return null;
    }

    public static calculateAcquisitionCostAssumption(percentage: number | null, sellingPrice: number | null, acquisitionCostAssumptionPercentage: number): number | null {
        try {
            const totalPriceFromPrice = EstateUtils.calculatePriceFromOwnership(percentage, sellingPrice);
            if (totalPriceFromPrice === null) { return null; }

            return parseFloat(Math.round((totalPriceFromPrice * acquisitionCostAssumptionPercentage)).toFixed(2));
        } catch (error) {
            console.error(`EstateUtils:calculateAcquisitionCostAssumption; ex=${error}`);
        }
        return null;
    }

    public static parseNumber(numberString: string, precision: number = 2): number | null {
        try {
            const parsedNumber = parseFloat(parseFloat(numberString).toFixed(precision));
            return !isNaN(parsedNumber) ? parsedNumber : null;
        } catch (error) {
            console.error(`EstateUtils:parseNumber; ex=${error}`);
        }

        return null;
    }

    public static calculateEstateCostImprovementExpenses(estatePercentage: number | null, estateUnclaimedDepreciationImprovementCosts: number | null): number | null {
        try {
            if (estatePercentage === null || estateUnclaimedDepreciationImprovementCosts == null) { return null; }
            return estateUnclaimedDepreciationImprovementCosts * (estatePercentage / 100);
        } catch (error) {
            console.error(`EstateUtils:calculateEstateCostImprovementExpenses; ex=${error}`);
        }

        return null;
    }

    public static calculateTotalForestEstateSalesCostsYield(estateSellingExpenses: number | null, estatePercentage: number | null): number | null {
        try {
            if (estateSellingExpenses === null || estatePercentage == null) { return null; }
            return estateSellingExpenses * (estatePercentage / 100);
        } catch (error) {
            console.error(`EstateUtils:calculateTotalForestEstateSalesCostsYield; ex=${error}`);
        }

        return null;
    }

    public static calculateTotalEstateCosts(
        estatePurchasePrice: number,
        inheritanceTax: number,
        giftTaxi: number,
        stampDutyTax: number,
        landRegistrationCost: number,
        landSurveyingPrice: number,
        otherestateCosts: number,
        estateCostImprovementExpenses: number,
        forestEstateSalesCostsYield: number): number {
        return estatePurchasePrice + inheritanceTax + giftTaxi + stampDutyTax + landRegistrationCost + landSurveyingPrice + otherestateCosts + estateCostImprovementExpenses + forestEstateSalesCostsYield;
    }

    public static dateDifferenceIsGreaterThanYears(endDate: string, startDate: string, years: number): boolean {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const differenceInYears = end.getFullYear() - start.getFullYear();
        if (differenceInYears === years) {
            const monthDiff = end.getMonth() - start.getMonth();
            if (monthDiff === 0) {
                return end.getDate() >= start.getDate();
            }
            return monthDiff > 0;
        }
        return differenceInYears > years;
    }

    public static getAcquisitionCostAssumption(estateDate: string, estateConveyanceDate: string, isNaturalPerson: boolean, estatePercentage: number | null, estateSellingPrice: number | null): number | null {
        if (estateDate === "" || estateConveyanceDate === "") { return null; }

        let discountPersentage: number | undefined = !isNaturalPerson ? EstateUtils.ACAPercentages.nonNaturalPerson.percentage : undefined;
        if (discountPersentage === undefined) {
            discountPersentage = EstateUtils.ACAPercentages.higher.isValidYearRequirements(estateConveyanceDate, estateDate) ? EstateUtils.ACAPercentages.higher.percentage : EstateUtils.ACAPercentages.normal.percentage;
        }

        return EstateUtils.calculateAcquisitionCostAssumption(estatePercentage, estateSellingPrice, discountPersentage);
    }
}
