import { IEstate } from "../interfaces/IEstate";

export const initialEstate: IEstate = {
    estateType: "",
    estateDate: "",
    estateFraction: "",
    estatePercentage: null,
    estatePurchasePrice: 0,
    inheritanceTax: 0,
    giftTax: 0,
    stampDutyTax: 0,
    landRegistrationCost: 0,
    landSurveyingPrice: 0,
    otherestateCosts: 0,
    estateCostAssumption: false,
    forestBaseReduction: null
}