import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// translation catalog
const resources = {
  fi: {
    translation: {
      'languageTitle': 'Vaihda kieli / Växla språk',
      'languageChangedToFinnish': "Kieli vaihdettu suomeksi",
      'calculatorFieldsEditedAfterCalculation': "Lomaketta on muokattu. Laske luovutusvoitto uudelleen ennen kuin voit tulostaa lomakkeen",
      'noCalculatedValue': "Ei laskettua arvoa",
      'calculatedValue': 'Laskettu arvo {$value}',
      'headerTitle': 'Metsätilan luovutusvoittoverolaskuri',
      'footerText': 'Laskurin toteuttajat eivät anna käyttäjälle takuuta laskurissa olevan tiedon ajantasaisuudesta, virheettömyydestä tai käyttökelpoisuudesta tiettyyn nimenomaiseen tarkoitukseen. Laskurin toteuttajat eivät myöskään millään tavoin takaa laskurin käyttäjälle tietyn tai halutun lopputuloksen saavuttamista kaikissa olosuhteissa. Laskurin toteuttajat eivät vastaa suhteessa käyttäjään kulusta, menetyksestä tai vahingosta, joka mahdollisesti aiheutuu laskurin käyttämisestä.',
      'yes': "Kyllä",
      'no': "Ei",
      'remove': "Poista",
      'allFieldsRequired': "Kaikki kentät ovat pakollisia täyttää",
      'tooltipAriaLabel': "Lisätietoa",
      'dateFormat': 'PP.KK.VVVV',

      'headerInfoColumn1': 'Laskurilla voi arvioida metsäkiinteistön luovutusvoiton tai -tappion ja hankintameno-olettaman sekä luovutusvoitosta maksettavan veron määrän.',
      'headerInfoColumn2': 'Laskuri soveltuu parhaiten tilanteisiin, joissa yhden henkilön tai kuolinpesän omistama metsätila myydään kokonaisuutena (ei siis määräosan ja määräalan myynnin tai lahjanluonteisen kaupan luovutusvoiton laskentaan).',
      'headerInfoColumn3': 'Jos myytävällä tilalla on kaksi tai useampi omistaja, voidaan laskea myytävän tilan luovutusvoitto tai -tappio mutta laskurin antama arvio luovutusvoiton verosta ei ole tarkka, vaan progressiovaikutus voi muuttaa lopullista maksettavan veron määrää.',
      'headerInfoColumn4': 'Jos myytävä metsätila on saatu yhtenä saantona esimerkiksi peritty tai ostettu yhtenä kokonaisuutena, syötetään laskuriin tämä yksi saanto.',
      'headerInfoColumn5': 'Jos myytävä metsätila on saatu vaiheittain, esimerkiksi osa perintönä ja osa ostettu sisaruksilta, laskuriin voidaan erikseen syöttää kaikki saannot hankintamenon määrittelyä varten.',

      'estateInfoHeader': 'Myytävän kohteen tiedot',
      'estateNameAndId': 'Kiinteistön nimi ja kiinteistötunnus',
      'estateNameAndIdToolTip': 'Myytävän kiinteistön nimi ja kiinteistötunnus. Tieto näkyy tunnisteena raportissa.',
      'invalidestateNameAndIdMessage': 'Täytä kiinteistön nimi ja kiinteistötunnus tekstikenttä',
      'estateHectareArea': 'Myytävän kiinteistön tai kiinteistöjen pinta-ala, ha',
      'estateHectareAreaTooltip': 'Myytävän metsäkiinteistön pinta-ala hehtaareina (yhden desimaalin tarkkuudella). Tieto näkyy raportissa.',
      'invalidEstateHectareAreaMessage': 'Täytä metsäkiinteistön pinta-ala kenttä hehtaareina',
      'estateConveyanceDate': 'Luovutusajankohta, pvm',
      'estateConveyanceDateTooltip': 'Suunniteltu tai toteutunut kaupantekopäivä. Omistusaika vaikuttaa hankintameno-olettaman suuruuteen.',
      'invalidEstateConveyanceDateMessage': 'Täytä luovutusajankohta päivämäärä kenttä',
      'estateSellingPrice': 'Myyntihinta €',
      'estateSellingPriceTooltip': 'Metsäkiinteistön arvioitu tai toteutunut myyntihinta, €.',
      'invalidEstateSellingPriceMessage': 'Täytä myyntihinta kenttä',
      'nonNaturalPerson': 'Myytävän kohteen ostaja on valtio, kunta, kuntayhtymä, hyvinvointialue tai -yhtymä tai Ahvenanmaan maakunta',
      'nonNaturalPersonTooltip': 'Jos kohteen ostaja on valtio, kunta, kuntayhtymä, hyvinvointialue tai -yhtymä tai Ahvenanmaan maakunta, merkitse tähän rasti. Tällöin hankintameno-olettama on  80 % myyntihinnasta.',
      'estateUnclaimedDepreciationImprovementCosts': 'Myytävän metsäkiinteistön poistamattomat perusparannusmenot',
      'estateUnclaimedDepreciationImprovementCostsTooltip': 'Jos myytävään metsään kohdistuu poistamattomia ojituksen, tien rakentamisen tai metsätalouden rakennuksen kustannuksia, näiden poistamaton menojäännös tai muu kiinteistöön kohdistuva perusparannusmeno syötetään tähän kenttään.',
      'invalidEstateUnclaimedDepreciationImprovementCostsMessage': 'Täytä poistamattomat perusparannusmenot kenttä',
      'estateSellingExpenses': 'Myyntikulut',
      'estateSellingExpensesTooltip': 'Metsän myyntikuluja ovat esim. metsän arvonmääritys, kauppakirjan laadinta, kiinteistönvälittäjän palkkio, asiamiespalkkiot, ilmoituskulut tai myyntiin liittyvät matkakulut.',
      'invalidEstateSellingExpensesMessage': 'Täytä myyntikulut kenttä',

      'estateCostInfoHeader': 'Myytävän kohteen hankintameno',
      'estateHeader': 'Saanto {$estate}',
      'estateType': 'Saantotapa (esim. perintö, lahja tai kauppa)',
      'estateTypeTooltip': 'Tähän kirjoitetaan saantotapa, perintö, lahja, kauppa, tms. Tieto näkyy raportilla. Jos tilan omistus on siirtynyt vaiheittain, laitetaan jokainen saanto erikseen. Uusi kenttä aukeaa painikkeesta "Lisää saanto".',
      'invalidEstateType': 'Täytä saantotapa kenttä',
      'estateDate': 'Saannon ajankohta, pvm',
      'estateDateTooltip': 'Myytävän kiinteistön tai sen osan saannon ajankohta, esimerkiksi perinnönjättäjän kuolinpäivä, perinnön jakokirjan, lahjakirjan tai kauppakirjan päiväys tai luovutuskirjassa sovittu muu omistusoikeuden siirtymispäivä.',
      'invalidEstateDate': 'Täytä saannon ajankohta kenttä',
      'estatePercentageAcquiredInput': 'Saannon osuus kiinteistöstä määräosana',
      'estatePercentageAcquiredInputTooltip': 'Kiinteistöstä saannossa siirtynyt osuus määräosana. Jos on ostettu/peritty koko kiinteistö saanto on 1/1. Jos saannossa on ostettu/peritty esim. puolet kiinteistöstä, on saanto 1/2.',
      'invalidEstatePercentageAcquiredInput': 'Täytä Saannon osuus määräosa kenttä',
      'estatePercentageSold': 'Saannon osuus kiinteistöstä %',
      'estatePercentageSoldTooltip': "Saannon osuus myytävästä kiinteistöstä prosentteina (%), jonka laskuri määrittelee annetun määräosan (murtoluvun) perusteella.",
      'estatePercentFromPrice': 'Saannon osuus kohteen myyntihinnasta, €.',
      'estatePercentFromPriceTooltip': 'Saantoon kohdistuva osuus myytävän kohteen kokonaismyyntihinnasta.',
      'estateCostOfTheYieldHeader': 'Saannon hankintameno',
      'estatePurchasePrice': "Ostohinta",
      'estatePurchasePriceTooltip': "Jos myynnin kohde on saatu kokonaan tai osittain ostamalla, tähän kenttään syötetään ostohinta.",
      'inheritanceTaxValue': "Perintöverotusarvo",
      'inheritanceTaxValueTooltip': 'Jos myynnin kohde on saatu perintönä, tähän kenttään syötetään saannon perintöverotusarvo.',
      'giftTaxValue': 'Lahjaverotusarvo',
      'giftTaxValueTooltip': 'Jos myynnin kohde on saatu kokonaan tai osittain lahjana (tai ennakkoperintönä), tähän kenttään syötetään saannon lahjaverotusarvo. Jos lahjansaaja luovuttaa saamansa omaisuuden ennen kuin lahjoituksesta on kulunut yksi vuosi, hankintameno on laskettava lahjoittajan hankintamenosta.',
      'stampDuty': 'Varainsiirtovero (leimavero ennen v 1997)',
      'stampDutyTooltip': 'Tähän kenttään syötetään kiinteistön ostossa maksettu varainsiirtoveron tai mahdollinen leimaveron määrä.',
      'landRegistration': "Lainhuudatus",
      'landRegistrationTooltip': "Tähän kenttään syötetään kiinteistön saannon lainhuudatuksesta maksettu viranomaismaksu.",
      'landSurveying': 'Lohkominen tai halkominen',
      'landSurveyingTooltip': 'Tähän kenttään syötetään kiinteistön hankinnassa mahdollisesti maksettu lohkomisen tai halkomisen hinta.',
      'otherestateCosts': "Muut metsän hankkimisesta aiheutuneet menot",
      'otherestateCostsTooltip': "Muita kuluja voivat olla esim. metsän arvonmääritys, kaupanvahvistajan palkkio, kauppakirjan laatiminen, asiamiespalkkio tai hankintaan liittyvät matkakulut.",
      'estateCostImprovementExpenses': 'Saantoon kohdistuva perusparannusmenojen lisäys',
      'estateCostImprovementExpensesTooltip': 'Laskurin laskema, saantoon kohdistuva metsäkiinteistön perusparannusmenojen suhteellinen osuus. ',
      'forestEstateSalesCostsYield': 'Saantoon kohdistuva osuus metsäkiinteistön myyntikuluista',
      'forestEstateSalesCostsYieldTooltip': 'Laskurin laskema, saantoon kohdistuva metsäkiinteistön myyntikulujen suhteellinen osuus.',
      'actualestateCost': 'Saannon {$estate} todellinen hankintameno',
      'actualestateCostTooltip': 'Laskurin laskema, syötettyihin arvoihin perustuva saannon todellinen hankintameno.',
      'estateCostAssumption': "Saannolle {$estate} muodostuva hankintameno-olettama",
      'estateCostAssumptionTooltip': "Myyntihinnan ja metsän omistusajan perusteella saannon osuudelle metsätilasta laskettu hankintameno-olettama, jota voidaan käyttää luovutusvoittoveron laskennassa. Jos ostaja on valtio, kunta, kuntayhtymä, hyvinvointialue tai -yhtymä tai Ahvenanmaan maakunta, on hankintameno-olettama 80 %",
      'estateCostAssumptionInTheCalculationCheckbox': 'Käytä hankintameno-olettamaa luovutusvoiton laskennassa',
      'estateCostAssumptionInTheCalculationCheckboxTooltip': 'Jos hankintameno-olettama on todellista hankintamenoa suurempi, verovelvollisen kannattaa käyttää hankintameno-olettamaa. Merkitse silloin rasti tähän.',
      'estateAddButton': 'Lisää saanto',

      'compensationInfoHeader': 'Vahingonkorvaukset',
      'compensation': 'Myytävään kiinteistöön kohdistuneet, myyjän saamat vahingonkorvaukset',
      'compensationTooltip': 'Myyjän luovutusvuonna ja viitenä edeltävänä vuonna saama, kaupan kohteena olevaan metsäkiinteistöön kohdistuva vahingonkorvaus, jota ei ole käytetty vahingoittuneen metsän tai muun kohteen kunnostamiseen eikä lisätty metsätalouden pääomatulona verotettavaksi.',
      'invalidCompensationMessage': 'Täytä kiinteistöön kohdistuvat vahingonkorvaukset kenttä',

      'forestReductionInfoHeader': 'Metsävähennys',
      'sellerTotalForestReduction': 'Myyjän käyttämä metsävähennys yhteensä',
      'sellerTotalForestReductionTooltip': 'Tähän kenttään syötetään myyjän aiemmin käyttämän metsävähennyksen kokonaismäärä.',
      'invalidSellerTotalForestReductionMessage': 'Täytä myyjän käyttämä metsävähennys kenttä',
      'forestBaseReduction': 'Saannossa {$estate} tullut metsävähennyspohja',
      'forestBaseReductionTooltip': 'Lisää tähän kohtaan ostohinnan ja muiden hankintamenojen perusteella muodostunut metsävähennyspohja ja/tai vastikkeettoman saannon mukana mahdollisesti tullut metsävähennyspohja yhteensä {$estate} saannosta. Jos saanto ei ole muodostanut metsävähennyspohjaa, kenttään jää arvo 0.',
      'invalidForestBaseReductionMessage': 'Täytä saannossa {$estate} tullut metsävähennyspohja kenttä',
      'totalForestBaseReduction': 'Saannoissa tullut metsävähennyspohja yhteensä, luovutusvoittoon lisättävän metsävähennyksen enimmäismäärä',
      'totalForestBaseReductionTooltip': "Myytävän metsän saannoissa myyjälle muodostunut metsävähennyspohja, joka voi perustua ostohintaan tai vastikkeettomassa saannossa siirtyneeseen metsävähennyspohjaan. Tästä muodostuu tuloutettavan metsävähennyksen enimmäismäärä.",
      'disposalProfitForestReduction': 'Luovutusvoittoon lisättävä käytetyn metsävähennyksen määrä',
      'disposalProfitForestReductionTooltip': 'Myyjän käyttämän metsävähennyksen ja myytävän metsän saannossa muodostuneen metsävähennyspohjan perusteella laskettu, myyntivoittoon lisättävä metsävähennys.',

      'formCalculationsInfoHeader': 'Luovutusvoiton laskenta',
      'formCalculationsTotalPrice': 'Myyntihinta ja siihen lisättävät erät yhteensä',
      'formCalculationsTotalPriceTooltip': 'Metsäkiinteistön myyntihinta ja siihen mahdollisesti lisättävä erä, kuten kiinteistöön kohdistuva vahingonkorvaus.',
      'formCalculationsTotalEstateAcquisitionCosts': 'Metsäkiinteistön hankinnan menot yhteensä',
      'formCalculationsTotalEstateAcquisitionCostsTooltip': 'Myyntihinnasta ja siihen mahdollisesti lisätyistä eristä vähennettävät metsäkiinteistön hankintamenot (tai hankintameno-olettama) luovutusvoiton tai -tappion laskemiseksi.',
      'formCalculationsTotalProfitsOrLosses': 'Luovutusvoitto tai -tappio',
      'formCalculationsTotalProfitsOrLossesTooltip': 'Laskurin tuottama, metsäkiinteistön myyntihinnan, siihen lisättävien erien ja hankintamenojen perusteella laskettu luovutusvoitto- tai tappio.',
      'formCalculationsTotalProfitsOrLossesWithForestReduction': 'Veronalainen luovutusvoitto tai -tappio, johon on lisätty tuloutettava metsävähennys',
      'formCalculationsTotalProfitsOrLossesWithForestReductionTooltip': 'Metsän myynnistä muodostuva luovutusvoitto tai tappio laskuriin syötettyjen myyntihinnan ja hankintamenojen mukaan, johon on lisätty mahdollinen tuloutettava metsävähennys.',
      'minTax': 'vähimmäismäärä',
      'maxTax': 'enimmäismäärä',
      'totalTax': 'Luovutusvoitosta maksettavan veron vähimmäis- ja enimmäismäärät',
      'totalTaxTooltip': 'Luovutusvoiton vaikutus maksettavien verojen lopulliseen määrään riippuu verovuoden pääomatulojen kokonaismäärästä ja lopullinen vero-% vaihtelee 30-34 %. Luovutustappion voi vähentää verovuoden muista luovutusvoitoista tai pääomatulaoista.',

      'calculateButton': 'Laske',
      'calculationDoneAccessibilityNotification': 'Lomakkeen laskenta valmis. Laskennat löytyvät alemmasta osiosta',
      'printReportButton': 'Tulosta raportti',
      'report': 'Laskelma metsätilakaupan luovutusvoiton verosta tai tappiosta',
      'reportHeading': 'Laskelma metsätilakaupan luovutusvoiton verosta tai tappiosta'
    }
  },
  sv: {
    translation: {
      'languageTitle': 'Växla språk / Vaihda kieli',
      'languageChangedToSweedish': "Språket ändrats till svenska",
      'calculatorFieldsEditedAfterCalculation': "Det har gjorts ändringar på blanketten. Beräkna överlåtelsevinsten på nytt förrän du kan skriva ut blanketten",
      'noCalculatedValue': "Inget beräknat värde",
      'calculatedValue': 'Beräknat värde {$value}',
      'headerTitle': 'Kalkylator för skatt på överlåtelsevinst från skogsfastighet',
      'footerText': 'Utgivarna av kalkylatorn ger inga garantier åt användarna beträffande informationens aktualitet, felfrihet eller användbarhet för vissa specifika ändamål. Utgivarna ger heller inga garantier för att användaren uppnår ett visst  eller önskat slutresultat i alla situationer. Utgivarna ansvarar inte heller gentemot användaren för kostnader, förluster eller skada som eventuellt orsakas av användningen av kalkylatorn.',
      'yes': "Ja",
      'no': "Nej",
      'remove': "Radera",
      'allFieldsRequired': 'Alla fält är obligatoriska',
      'tooltipAriaLabel': "Ytterligare info",
      'dateFormat': 'DD.MM.ÅÅÅÅ',

      'headerInfoColumn1': 'Med kalkylatorn kan uppskattas överlåtelsevinst eller -förlust och presumtiv anskaffningsutgift för skogsfastigheten, samt beloppet av skatt som skall betalas för överlåtelsevinsten .',
      'headerInfoColumn2': 'Kalkylatorn lämpar sig bäst för situationer där en person eller ett dödsbo säljer skogsfastighet som helhet (således inte för beräkning av överlåtelsevinst vid försäljning av kvotdel, viss areal eller gåvoartat köp).',
      'headerInfoColumn3': 'Om det finns två eller flera ägare på den fastighet som säljs, kan man beräkna fastighetsförsäljningens överlåtelsevinst eller -förlust men kalkylatorns uppskattade beräkning av skatten på överlåtelsevinsten är inte exakt. Inverkan av skatteskalornas progressivitet kan ändra beloppet av den slutliga skatt som skall erläggas.',
      'headerInfoColumn4': 'Om den fastighet som säljs erhållits i sin helhet som ett förvärv, exempelvis via arv eller köp som en helhet, fylls detta enda förvärv i kalkylatorn.',
      'headerInfoColumn5': 'Om den fastighet som säljs har erhållits i olika skeden, exempelvis som del av arv och del köpt av syskon, kan i kalkylatorn separat fyllas i alla förvärv för bestämmande av anskaffningsutgiften.',

      'estateInfoHeader': 'Uppgifter över objektet som säljs',
      'estateNameAndId': 'Fastighetens namn och registerbeteckning',
      'estateNameAndIdToolTip': 'Namn och fastighetsbeteckning på fastigheten som säljs. Uppgiften syns som identifikation i rapporten.',
      'estateHectareArea': 'Areal, ha på den fastighet eller de fastigheter som säljs',
      'estateHectareAreaTooltip': 'Areal i hektar på den skogsfastighet som säljs  (med en decimals noggrannhet). Uppgiften syns i rapporten.',
      'estateConveyanceDate': 'Tidpunkt för överlåtelse, datum',
      'estateConveyanceDateTooltip': 'Planerad eller verklig tidpunkt för köpet. Ägotiden inverkar på storleken av den presumtiva anskaffningsutgiften.',
      'estateSellingPrice': 'Försäljningspris €',
      'estateSellingPriceTooltip': 'Skogsfastighetens uppskattade eller verkliga försäljningspris, €.',
      'nonNaturalPerson': 'Köpare till det objekt som säljs är stat, kommun, kommunförbund, välfärdsområde eller - sammanslutning eller landskapet Åland',
      'nonNaturalPersonTooltip': 'Om köpare till objektet är stat, kommun, kommunförbund, välfärdsområden eller -sammanslutning eller landskapet Åland, sätt kryss här. Därvidlag är den presumtiva anskaffningsutgiften 80 % av försäljningspriset.',
      'estateUnclaimedDepreciationImprovementCosts': 'Oavskrivna grundförbättringskostnader på den skogsfastighet som säljs',
      'estateUnclaimedDepreciationImprovementCostsTooltip': 'Om det finns oavskrivna kostnader för dikning, vägbyggnad eller skogsbrukets byggnader som anses höra till den skogsfastighet som säljs, fyll i den oavskrivna utgiftsresten eller annan till fastigheten hörande grundförbättringsutgift i detta fält.',
      'estateSellingExpenses': 'Kostnader för försäljning',
      'estateSellingExpensesTooltip': 'Kostnader som hör till skogsfastihetens försäljning är ex. skogsvärdering, uppgörande av köpeavtal, arvode för fastighetsförmedlare, ombudsmannaarvoden, annonskostnader eller resekostnader som hör till försäljningen.',

      'estateCostInfoHeader': 'Anskaffningsutgift för objektet som säljs',
      'estateHeader': 'Förvärv {$estate}',
      'estateType': 'Förvärvssätt (exempelvis arv, gåva eller köp)',
      'estateTypeTooltip': 'Här skrivs förvärvssätt, arv, gåva, köp eller dylikt. Uppgiften syns på rapporten. Om ägandet för lägenheten övergått stegvis, läggs varje förvärv skilt. Ett nytt fält öppnar sig via knappen "Lägg till förvärv".',
      'estateDate': 'Tidpunkt för förvärvet, datum',
      'estateDateTooltip': 'Tidpunkt för förvärv av den lägenhet eller del av den som säljs, exempelvis arvlåtarens dödsdag, arvets bodelningsinstrument, datum för uppgörande av gåvobrev eller köpebrev eller i överlåtelsebrevet annan överenskommen tidpunkt för äganderättens övergång.',
      'estatePercentageAcquiredInput': 'Förvärvets andel av fastigheten som kvotdel',
      'estatePercentageAcquiredInputTooltip': 'Andel av lägenheten i kvotdel som övergått i förvärvet. Om hela lägenheten köpts/ärvts i förvärvet är kvotdelen 1/1.  Om i förvärvet köpts/ärvts exempelvis hälften av fastigheten är förvärvet 1/2.',
      'estatePercentageSold': 'Förvärvets andel av fastigheten i  %',
      'estatePercentageSoldTooltip': "Förvärvets andel av den lägenhet som säljs i procent (%), vilket kalkylatorn bestämmer utgående från den angivna kvotdelen (bråktalet).",
      'estatePercentFromPrice': 'Förvärvets andel av objektets försäljningspris, €.',
      'estatePercentFromPriceTooltip': 'Förvärvets andel av det sålda objektets totala försäljningspris.',
      'estateCostOfTheYieldHeader': 'Förvärvets anskaffningsutgift',
      'estatePurchasePrice': "Köpesumma",
      'estatePurchasePriceTooltip': "Om objektet för försäljningen anskaffats helt eller delvis genom köp, ange köpesumman i detta fält.",
      'inheritanceTaxValue': "Värde vid arvsbeskattningen",
      'inheritanceTaxValueTooltip': 'Om objektet för försäljningen erhållits genom arv, anges värdet vid arvsbeskattningen i detta fält.',
      'giftTaxValue': 'Värde vid gåvobeskattningen',
      'giftTaxValueTooltip': 'Om objektet för försäljningen erhållits helt eller delvis som gåva (eller förskott på arv), ange förvärvets värde vid gåvobeskattningen i detta fält. Anskaffningsutgiften beräknas utifrån gåvogivarens anskaffningsutgift om gåvomottagaren överlåter den egendom han eller hon erhållit innan ett år har förflutit från gåvan.',
      'stampDuty': 'Överlåtelseskatt (stämpelskatt före år 1997)',
      'stampDutyTooltip': 'I detta fält anges i samband med köpet erlagd överlåtelseskatt eller eventuell stämpelskatt.',
      'landRegistration': "Lagfartsansökan",
      'landRegistrationTooltip': "I detta fält anges myndighetsavgift som erlagts i samband med fastighetens lagfartsansökan.",
      'landSurveying': 'Styckning eller klyvning',
      'landSurveyingTooltip': 'I detta fält anges eventuell avgift som erlagts i samband med styckning eller klyvning av fastigheten.',
      'otherestateCosts': "Övriga utifter som hänför sig till anskaffningen av skogen",
      'otherestateCostsTooltip': "Övriga utgifter kan vara exempelvis skogsvärdering, arvode för köpvittne, uppgörande av köpebrev, ombudsmanna-arvoden eller resekostnader som hänför sig till förvärvet.",
      'estateCostImprovementExpenses': 'Tillägg för grundförbättringskostnader som hänför sig till förvärvet',
      'estateCostImprovementExpensesTooltip': 'Kalkylatorns uträkning av de relativa andelar för kostnader för grundförbättring som hör till skogsfastigheten.',
      'forestEstateSalesCostsYield': 'Förvärvets andel av kostnader som hör skogsfastighetens försäljningskostnader',
      'forestEstateSalesCostsYieldTooltip': 'Kalkylatorns uträkning av de relativa kostnader som hör till förvärvets andel av skogsfastighetens försäljningskostnader.',
      'actualestateCost': 'Förvärvets {$estate} verklig anskaffningsutgift',
      'actualestateCostTooltip': 'Kalkylatorns uträkning av förvärvets verkliga anskaffningsutgift på basen av inmatade uppgifter.',
      'estateCostAssumption': "Förvärvets {$estate} bildade presumtiva anskaffningsutgift",
      'estateCostAssumptionTooltip': "Presumtiv anskaffningsutgift för förvärvet uträknad på försäljningspris och ägandetiden för skogen, vilken kan användas vid uträkningen av skatt på överlåtelsevinst. Om köparen är stat, kommun, kommunförbund, välfärdsområde eller -sammanslutning eller landskapet Åland, är den presumtiva anskaffningsutgiften 80 %",
      'estateCostAssumptionInTheCalculationCheckbox': 'Använd den presumtiva anskaffningsutgiften vid uträkningen av överlåtelsevinst',
      'estateCostAssumptionInTheCalculationCheckboxTooltip': 'Om den presumtiva anskaffningsutgiften är större än verklig anskaffningsutgift, lönar det sig för den skattskyldige att använda den presumtiva anskaffningsutgiften. Ange då kryss här.',
      'estateAddButton': 'Lägg till förvärv',

      'compensationInfoHeader': 'Skadeersättningar',
      'compensation': 'Skadeersättningar som hör till den lägenhet som säljs, säljarens erhållna skadeersättningar',
      'compensationTooltip': 'Skadeersättning som säljaren erhållit under överlåtelseåret och fem föregående år, och anses höra till den skogsfastighet som är föremål för köpet, vilken inte använts för iståndsättning av den skadade skogen eller annat objekt och inte heller lagt till skogsbrukets beskattningsbara kapitalinkomst.',

      'forestReductionInfoHeader': 'Skogsavdrag',
      'sellerTotalForestReduction': 'Skogsavdrag som säljaren använt sammanlagt',
      'sellerTotalForestReductionTooltip': 'I detta fält anges totalsumman av det skogsavdrag som säljaren använt tidigare.',
      'forestBaseReduction': 'Vid förvärv {$estate} uppkommen skogsavdragsgrund',
      'forestBaseReductionTooltip': 'Ange i denna punkt den sammanlagda skogsavdragsgrund som uppstår på basen av köpesumman och andra till köpet hörande bikostnader och/eller den avdragsgrund som eventuellt medföljer den vederlagsfria överlåtelsen från det  1. förvärvet. Om ingen avdragsgrund uppstått vid förvärvet, lämnas värdet 0 i fältet.',
      'totalForestBaseReduction': 'Vid förvärven uppkommen skogsavdragsgrund sammanlagt, maxgräns för det skogsavdrag som skall läggas till överlåtelsevinsten',
      'totalForestBaseReductionTooltip': "Skogsavdragsgrund som uppstått hos säljaren vid förvärvet av den skog som nu säljs, denna kan basera sig på köpesumma eller skogsavdragsgrund som överflyttats vid vederlagsfria överlåtelser. Av detta bildas maxgränsen för det skogsavdrag som skall inkomstföras.",
      'disposalProfitForestReduction': 'Storleken på det använda skogsavdrag som skall läggas till överlåtelsevinsten',
      'disposalProfitForestReductionTooltip': 'Skogsavdrag som skall läggas till försäljningsvinsten, uträknat enligt det skogsavdrag som säljaren använt och säljarens skogsavdragsgrund  vid förvärvstillfället för den skog som nu säljs.',

      'formCalculationsInfoHeader': 'Beräkning av överlåtelsevinst',
      'formCalculationsTotalPrice': 'Försäljningspris och poster som läggs till sammanlagt',
      'formCalculationsTotalPriceTooltip': 'Skogsfastighetens försäljningspris och därtill eventuella hörande poster såsom skadeersättningar som hör till fastigheten.',
      'formCalculationsTotalEstateAcquisitionCosts': 'Anskaffningsutgifter för skogsfastigheten sammanlagt',
      'formCalculationsTotalEstateAcquisitionCostsTooltip': 'Anskaffningsutgift (eller presumtiv anskaffningsutgift) och andra tilläggsposter som skall dras bort från försäljningspriset för skogsfastigheten för uträkning av överlåtelsevinst eller -förlust.',
      'formCalculationsTotalProfitsOrLosses': 'Överlåtelsevinst eller -förlust',
      'formCalculationsTotalProfitsOrLossesTooltip': 'Överåtelsevinst eller -förlust, baserad på kalkylatorns uppgifter om skogsfastighetens försäljningspris, anskaffningspris och därtill hörande tilläggsposter.',
      'formCalculationsTotalProfitsOrLossesWithForestReduction': 'Den beskattningsbara överlåtelsevinsten eller -förlusten, till vilken lagts skogsavdrag som skall inkomstföras',
      'formCalculationsTotalProfitsOrLossesWithForestReductionTooltip': 'Överlåtelsevinst eller -förlust från försäljningen av skogen, baserat på kalkylatorns uppgifter om försäljningspris och anskaffningsutgifter, till vilken lagts till eventuellt skogsavdrag som skall inkomstföras.',
      'minTax': 'Minimi',
      'maxTax': 'maximisummor',
      'totalTax': 'Minimi och maximisummor för den skatt på överlåtelsevinst som skall erläggas',
      'totalTaxTooltip': 'Storleken på den skatt som skall erläggas för överlåtelsvinsten är beroende av skatteårets sammanlagda kapitalinkomster, och den slutliga skatten varierar 30-34%. Förlust från överlåtelser kan dras av från skatteårets övriga överlåtelsevinster eller kapitalinkomster.',

      'calculateButton': 'Beräkna',
      'calculationDoneAccessibilityNotification': 'Beräkningen av blanketten är klar. Beräkningar finns nedan',
      'printReportButton': 'Skriv ut rapporten',

      'invalidestateNameAndIdMessage': 'Fyll fastighetens namn och registerbeteckning',
      'invalidEstateHectareAreaMessage': 'Fyll areal i hektar',
      'invalidEstateConveyanceDateMessage': 'Fyll tidpunkt för överlåtelse',
      'invalidEstateSellingPriceMessage': 'Fyll försäljningspris',
      'invalidEstateUnclaimedDepreciationImprovementCostsMessage': 'Fyll oavskrivna grundförbättringskostnader',
      'invalidEstateSellingExpensesMessage': 'Fyll kostnader för försäljning',
      'invalidEstateType': 'Fyll förvärvssätt',
      'invalidEstateDate': 'Fyll tidpunkt för förvärvet',
      'invalidEstatePercentageAcquiredInput': 'Fyll förvärvets andel av fastigheten som kvotdel',
      'invalidCompensationMessage': 'Fyll skadeersättningar som hör till den lägenhet',
      'invalidSellerTotalForestReductionMessage': 'Fyll skogsavdrag som säljaren använt',
      'invalidForestBaseReductionMessage': 'Fyll vid förvärv {$estate} uppkommen skogsavdragsgrund',
      'report': 'Beräkning av skatt eller förlust vid försäljning av skogsfastighet',
      'reportHeading': 'Beräkning av skatt eller förlust vid försäljning av skogsfastighet'
    }
  }
};

// initialize i18next with catalog and language to use
i18n.use(initReactI18next).init({
  resources,
  lng: 'fi'
});

export default i18n;