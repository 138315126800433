import { useTranslation } from "react-i18next";
import { ToolTipAction } from "./Tooltip";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ICalculator } from "../interfaces/ICalculator";
import { useCalculations } from "../hooks/useCalculations";
import { CalculationsUtils } from "../utils/CalculationsUtils";
import '../css/Calculations.css';
import { Typography } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

export const Calculations = forwardRef((_, ref) => {
    const { t } = useTranslation();
    const [state, setState] = useState<ICalculator>();
    const [isCalculated, setIsCalculated] = useState(false);
    const [totalPrice, totalEstateAcquisitionCosts, totalProfitsOrLosses, totalProfitsOrLossesWithForestReduction, totalTaxMin, totalTaxMax] = useCalculations({ state });

    useImperativeHandle(ref, () => ({
        calculate(calculationsState: ICalculator) {
            setState(calculationsState);
            setIsCalculated(true);
        },
        getIsCalculated(): boolean {
            return isCalculated;
        }
    }));

    return (
        <div className="sectionContainer">
            <div className="sectionHeading">
                <h2>{t("formCalculationsInfoHeader")}</h2>
            </div>
            <div className="sectionContainer">
                <div className="section calculationsSection">
                    <div className="sectionSplitter">
                        <div className="calculationRow">
                            <div className="calculationColumn">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("formCalculationsTotalPrice")}.</Typography>
                                            <Typography>{t("formCalculationsTotalPriceTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{state && totalPrice ? t("calculatedValue").replace("{$value}", CalculationsUtils.formatNumber(`${totalPrice}`)) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("formCalculationsTotalPrice")}</span>
                            </div>
                            <div className="calculationColumn calculatedField calculationColumnValue">
                                <span data-testid='data-test-id-total-price' aria-hidden>{!state ? "" : CalculationsUtils.formatNumber(`${totalPrice}`)}</span>
                            </div>
                        </div>

                        <div className="calculationRow">
                            <div className="calculationColumn">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("formCalculationsTotalEstateAcquisitionCosts")}.</Typography>
                                            <Typography>{t("formCalculationsTotalEstateAcquisitionCostsTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{state && totalEstateAcquisitionCosts ? t("calculatedValue").replace("{$value}", CalculationsUtils.formatNumber(`${totalEstateAcquisitionCosts}`)) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("formCalculationsTotalEstateAcquisitionCosts")}</span>
                            </div>
                            <div className="calculationColumn calculatedField calculationColumnValue">
                                <span data-testid='data-test-id-total-estate-acquisition-costs' aria-hidden>{!state ? "" : CalculationsUtils.formatNumber(`${totalEstateAcquisitionCosts}`)}</span>
                            </div>
                        </div>

                        <div className="calculationRow">
                            <div className="calculationColumn">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("formCalculationsTotalProfitsOrLosses")}.</Typography>
                                            <Typography>{t("formCalculationsTotalProfitsOrLossesTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{state && totalProfitsOrLosses ? t("calculatedValue").replace("{$value}", CalculationsUtils.formatNumber(`${totalProfitsOrLosses}`)) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("formCalculationsTotalProfitsOrLosses")}</span>
                            </div>
                            <div className="calculationColumn calculatedField calculationColumnValue">
                                <span data-testid='data-test-id-total-profits-or-losses' aria-hidden>{!state ? "" : CalculationsUtils.formatNumber(`${totalProfitsOrLosses}`)}</span>
                            </div>
                        </div>
                    </div>
                    <div className="sectionSplitter">
                        <div className="calculationRow">
                            <div className="calculationColumn">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("formCalculationsTotalProfitsOrLossesWithForestReduction")}.</Typography>
                                            <Typography>{t("formCalculationsTotalProfitsOrLossesWithForestReductionTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{state && totalProfitsOrLossesWithForestReduction ? t("calculatedValue").replace("{$value}", CalculationsUtils.formatNumber(`${totalProfitsOrLossesWithForestReduction}`)) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("formCalculationsTotalProfitsOrLossesWithForestReduction")}</span>
                            </div>
                            <div className="calculationColumn calculatedField calculationColumnValue">
                                <span data-testid='data-test-id-total-profits-or-losses-with-forest-reduction' aria-hidden>{!state ? "" : CalculationsUtils.formatNumber(`${totalProfitsOrLossesWithForestReduction}`)}</span>
                            </div>
                        </div>

                        <div className="calculationRow">
                            <div className="calculationColumn">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("totalTax")}.</Typography>
                                            <Typography>{t("totalTaxTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{`${t("minTax")} ${state && totalTaxMin ? t("calculatedValue").replace("{$value}", CalculationsUtils.formatNumber(`${totalTaxMin}`)) : t("noCalculatedValue")}. ${t("maxTax")} ${state && totalTaxMax ? t("calculatedValue").replace("{$value}", CalculationsUtils.formatNumber(`${totalTaxMax}`)) : t("noCalculatedValue")}`}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("totalTax")}</span>
                            </div>
                            <div className="calculationColumn totalTaxesMinMaxColumn">
                                <div className="calculatedField calculationColumnValue" style={{ justifyContent: "flex-end" }}>
                                    <span data-testid='data-test-id-total-tax-min' aria-hidden>{!state ? "" : CalculationsUtils.formatNumber(`${totalTaxMin}`)}</span>
                                </div>
                                <div className="calculatedField calculationColumnValue" style={{ justifyContent: "flex-end" }}>
                                    <span data-testid='data-test-id-total-tax-max' aria-hidden>{!state ? "" : CalculationsUtils.formatNumber(`${totalTaxMax}`)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})