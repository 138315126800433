import { ToolTipAction } from "./Tooltip";
import { useTranslation } from "react-i18next";
import { IEstateInfo } from "../interfaces/IEstate";
import { useEffect, useState } from "react";
import { EstateUtils } from "../utils/EstateUtils";
import { ICalculator } from "../interfaces/ICalculator";
import { initialEstateInfo } from "../models/MEstateInfo";
import { NumberInputField } from "./Inputs/NumberInputField";
import { ComponentUtils } from "../utils/ComponentUtils";
import { Box, Typography, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


interface IProps {
    onUpdateState: (updatedState: IEstateInfo, key: keyof ICalculator) => void;
}

export function EstateInfoComponent(props: IProps) {
    const { t, i18n } = useTranslation();
    const [estateInfo, setEstateInfo] = useState<IEstateInfo>(initialEstateInfo);

    useEffect(() => {
        // MUI doesn't allow set the field type, which by default is tel at current version
        // Force cange the field type to text, so screen reader read this field as text type
        const calendarInput = document.getElementById('estate-conveyance-date');
        calendarInput?.setAttribute("type", "text");
        calendarInput?.setAttribute("aria-live", "polite");
    }, []);

    useEffect(() => {
        // MUI doesn't allow set the field type, which by default is tel at current version
        // Force cange the field type to text, so screen reader read this field as text type
        const calendarInput = document.getElementById('estate-conveyance-date');
        calendarInput?.setAttribute("placeholder", t("dateFormat"));
    }, [i18n.language])

    useEffect(() => {
        props.onUpdateState(estateInfo, "estateInfo");
    }, [estateInfo]);

    function updateNumberField(value: string, key: keyof IEstateInfo): void {
        let updatedEstateInfo = { ...estateInfo };
        if (value === "") {
            (updatedEstateInfo[key] as number | null) = null;
            setEstateInfo(updatedEstateInfo);
            return;
        }

        const numberValue: number | null = EstateUtils.parseNumber(value);
        if (numberValue !== null) {
            (updatedEstateInfo[key] as number | null) = numberValue;
            setEstateInfo(updatedEstateInfo);
        }
    }

    return (
        <div className="sectionContainer">
            <div className="sectionHeading">
                <h2>{t("estateInfoHeader")}</h2>
            </div>
            <div className="section">
                <div className="row">
                    <div className="rowElement">
                        <div className="rowInputElement">
                            <input aria-label={`${t("estateNameAndId")}`} id="estate-id" type="text" data-testid="data-test-id-estate-id" maxLength={50} value={estateInfo.estateNameAndId} onChange={(event) => setEstateInfo({ ...estateInfo, estateNameAndId: event.target.value })} required onInvalid={() => ComponentUtils.formInputFieldInvalidNotification("estate-id", t("invalidestateNameAndIdMessage"))} />
                        </div>
                        <div className="rowElementHeader">
                            <ToolTipAction
                                text={
                                    <Typography>{t("estateNameAndIdToolTip")}</Typography>
                                }
                            />
                            <label aria-hidden htmlFor="estate-id">{t("estateNameAndId")}</label>
                        </div>
                    </div>
                    <div className="rowElement">
                        <div className="rowInputElement">
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                                <Box id={`estate-conveyance-date-container`}>
                                    <DatePicker
                                        inputFormat="DD.MM.YYYY"
                                        value={estateInfo.estateConveyanceDate || null}
                                        onChange={(newValue) => {
                                            if (newValue) {
                                                setEstateInfo({ ...estateInfo, estateConveyanceDate: dayjs(newValue).format('YYYY-MM-DD') })
                                            } else {
                                                setEstateInfo({ ...estateInfo, estateConveyanceDate: "" })
                                            }
                                        }}
                                        InputProps={{
                                            "aria-label": `${t("estateConveyanceDate")}`,
                                            className: "calendarInput",
                                            required: true,

                                        }}
                                        renderInput={(params) => <TextField variant="outlined" id={`estate-conveyance-date`} data-testid={`data-test-id-estate-conveyance-date`} {...params} onInvalid={() => ComponentUtils.formInputFieldInvalidNotification("estate-conveyance-date-container", t("invalidEstateConveyanceDateMessage"))} />}
                                    />
                                </Box>
                            </LocalizationProvider>
                        </div>
                        <div className="rowElementHeader">
                            <ToolTipAction
                                text={
                                    <Typography>{t("estateConveyanceDateTooltip")}</Typography>
                                }
                            />
                            <label aria-hidden htmlFor="estate-conveyance-date">{t("estateConveyanceDate")}</label>
                        </div>
                    </div>
                    <div className="rowElement">
                        <div className="rowInputElement">
                            <NumberInputField
                                ariaLabel={t("estateHectareArea")}
                                dataTestid="data-test-id-estate-hectare-area"
                                precision={1}
                                value={estateInfo.estateHectareArea}
                                onUpdateState={(value) => updateNumberField(value, "estateHectareArea")}
                                id="hectare-area"
                                onInvalid={() => ComponentUtils.formInputFieldInvalidNotification("hectare-area", t("invalidEstateHectareAreaMessage"))}
                            />
                        </div>
                        <div className="rowElementHeader">
                            <ToolTipAction
                                text={
                                    <Typography>{t("estateHectareAreaTooltip")}</Typography>
                                }
                            />
                            <label aria-hidden htmlFor="hectare-area">{t("estateHectareArea")}</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="rowElement">
                        <div className="rowInputElement">
                            <NumberInputField
                                ariaLabel={t("estateSellingPrice")}
                                dataTestid={"data-test-id-estate-selling-price"}
                                precision={2}
                                value={estateInfo.estateSellingPrice}
                                onUpdateState={(value) => updateNumberField(value, "estateSellingPrice")}
                                id="selling-price"
                                onInvalid={() => ComponentUtils.formInputFieldInvalidNotification("selling-price", t("invalidEstateSellingPriceMessage"))}
                            />
                        </div>
                        <div className="rowElementHeader">
                            <ToolTipAction
                                text={
                                    <Typography>{t("estateSellingPriceTooltip")}</Typography>
                                }
                            />
                            <label aria-hidden htmlFor="selling-price">{t("estateSellingPrice")}</label>
                        </div>
                    </div>
                    <div className="rowElement">
                        <div className="rowInputElement">
                            <NumberInputField
                                ariaLabel={t("estateSellingExpenses")}
                                precision={2}
                                dataTestid={"data-test-id-estate-selling-expenses"}
                                value={estateInfo.estateSellingExpenses}
                                onUpdateState={(value) => updateNumberField(value, "estateSellingExpenses")}
                                id="selling-expenses"
                                onInvalid={() => ComponentUtils.formInputFieldInvalidNotification("selling-expenses", t("invalidEstateSellingExpensesMessage"))}
                            />
                        </div>
                        <div className="rowElementHeader">
                            <ToolTipAction
                                text={
                                    <Typography>{t("estateSellingExpensesTooltip")}</Typography>
                                }
                            />
                            <label aria-hidden htmlFor="selling-expenses">{t("estateSellingExpenses")}</label>
                        </div>
                    </div>
                    <div className="rowElement">
                        <div className="rowInputElement">
                            <NumberInputField
                                ariaLabel={t("estateUnclaimedDepreciationImprovementCosts")}
                                precision={2}
                                dataTestid={"data-test-id-estate-unclaimed-depreciation-improvement-costs"}
                                value={estateInfo.estateUnclaimedDepreciationImprovementCosts}
                                onUpdateState={(value) => updateNumberField(value, "estateUnclaimedDepreciationImprovementCosts")}
                                id="unclaimed-depreciation-improvement-costs"
                                onInvalid={() => ComponentUtils.formInputFieldInvalidNotification("unclaimed-depreciation-improvement-costs", t("invalidEstateUnclaimedDepreciationImprovementCostsMessage"))}
                            />
                        </div>
                        <div className="rowElementHeader">
                            <ToolTipAction
                                text={
                                    <Typography>{t("estateUnclaimedDepreciationImprovementCostsTooltip")}</Typography>
                                }
                            />
                            <label aria-hidden htmlFor="unclaimed-depreciation-improvement-costs">{t("estateUnclaimedDepreciationImprovementCosts")}</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="rowElement">
                        <div className="rowElementHeader">
                            <div className="rowElementHeaderInputElement">
                                <input aria-label={`${t("nonNaturalPerson")}`} id="non-natural-person" data-testid={`data-test-id-non-natural-person`} type="checkbox" checked={estateInfo.nonNaturalPerson} onChange={(event) => setEstateInfo({ ...estateInfo, nonNaturalPerson: event.target.checked })} />
                            </div>
                            <ToolTipAction
                                text={
                                    <Typography>{t("nonNaturalPersonTooltip")}</Typography>
                                }
                            />
                            <label aria-hidden htmlFor="non-natural-person">{t("nonNaturalPerson")}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}