import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICalculator } from "../interfaces/ICalculator";
import { IEstate, IForestReduction } from "../interfaces/IEstate";
import { ToolTipAction } from "./Tooltip";
import { initialForestReduction } from "../models/MEstateForestReduction";
import { EstateUtils } from "../utils/EstateUtils";
import { CalculationsUtils } from "../utils/CalculationsUtils";
import { NumberInputField } from "./Inputs/NumberInputField";
import { ComponentUtils } from "../utils/ComponentUtils";
import { Typography } from "@mui/material";
import { visuallyHidden } from '@mui/utils';

interface IProps {
    estateCostInfo: IEstate[];
    onUpdateState: (updatedState: IForestReduction, key: keyof ICalculator) => void;
    onNotifyEstateUpdate: (updatedEstateCostInfo: IEstate[]) => void;
}

export function ForestReductionComponent(props: IProps) {
    const { t } = useTranslation();
    const [forestReduction, setForestReduction] = useState<IForestReduction>(initialForestReduction);

    useEffect(() => {
        props.onUpdateState({ ...forestReduction }, "estateForestReduction")
    }, [forestReduction])

    const calculatedTotalForestBaseReduction = useMemo(() => CalculationsUtils.formatNumber(`${CalculationsUtils.totalForestBaseReduction(props.estateCostInfo)}`, 2), [props.estateCostInfo]);
    const calculatedTotalForestBaseReductionMaxLimit = useMemo(() => CalculationsUtils.formatNumber(`${CalculationsUtils.calculatedTotalForestBaseReduction(forestReduction.sellerTotalForestReduction || 0, CalculationsUtils.totalForestBaseReduction(props.estateCostInfo))}`, 2), [props.estateCostInfo, forestReduction.sellerTotalForestReduction]);

    function onUpdateForestBaseReduction(index: number, updatedEstate: IEstate): void {
        const updatedEstates: IEstate[] = [...props.estateCostInfo];

        if (updatedEstates[index]) {
            updatedEstates[index] = updatedEstate;
        }

        props.onNotifyEstateUpdate(updatedEstates);
    }

    function getEstateIndexToText(index: number): number {
        return index + 1;
    }

    function renderEstate(estate: IEstate, index: number): JSX.Element {
        return (
            <div key={index} className="rowElement mt-10">
                <div className="rowInputElement">
                    <NumberInputField
                        ariaLabel={t("forestBaseReduction").replace("{$estate}", `${getEstateIndexToText(index)}.`)}
                        dataTestid={`data-test-id-forest-base-reduction-${index}`}
                        precision={2}
                        value={estate.forestBaseReduction}
                        onUpdateState={(value) => onUpdateForestBaseReduction(index, { ...estate, forestBaseReduction: EstateUtils.parseNumber(value) })}
                        id={`forest-base-reduction-${index}`}
                        onInvalid={() => ComponentUtils.formInputFieldInvalidNotification(`forest-base-reduction-${index}`, t("invalidForestBaseReductionMessage").replace("{$estate}", `${getEstateIndexToText(index)}.`))}
                    />
                </div>
                <div className="rowElementHeader">
                    <ToolTipAction
                        text={
                            <Typography>{t("forestBaseReductionTooltip").replace("{$estate}", `${getEstateIndexToText(index)}.`)}</Typography>
                        }
                    />
                    <label aria-hidden htmlFor="forest-base-reduction">{t("forestBaseReduction").replace("{$estate}", `${getEstateIndexToText(index)}.`)}</label>
                </div>
            </div>
        )
    }

    return (
        <div className="sectionContainer">
            <div className="sectionHeading">
                <h2>{t("forestReductionInfoHeader")}</h2>
            </div>
            <div className="sectionContainer">
                <div className="section">
                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement">
                                <NumberInputField
                                    ariaLabel={t("sellerTotalForestReduction")}
                                    dataTestid={`data-test-id-seller-total-forest-reduction`}
                                    precision={2}
                                    value={forestReduction.sellerTotalForestReduction}
                                    onUpdateState={(value) => setForestReduction({ ...forestReduction, sellerTotalForestReduction: EstateUtils.parseNumber(value) })}
                                    id="seller-total-forest-reduction"
                                    onInvalid={() => ComponentUtils.formInputFieldInvalidNotification(`seller-total-forest-reduction`, t("invalidSellerTotalForestReductionMessage"))}
                                />
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <Typography>{t("sellerTotalForestReductionTooltip")}</Typography>
                                    }
                                />
                                <label aria-hidden htmlFor="seller-total-forest-reduction">{t("sellerTotalForestReduction")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="row forestReductionEstateRow">
                        {props.estateCostInfo.map((estate, index: number) => renderEstate(estate, index))}
                    </div>
                    <div className="row">
                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={"data-test-id-total-forest-base-reduction"} aria-hidden>{calculatedTotalForestBaseReduction}</span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("totalForestBaseReduction")}</Typography>
                                            <Typography>{t("totalForestBaseReductionTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{calculatedTotalForestBaseReduction ? t("calculatedValue").replace("{$value}", calculatedTotalForestBaseReduction) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("totalForestBaseReduction")}</span>
                            </div>
                        </div>
                        <div className="rowElement">
                            <div className="rowInputElement calculatedField calculationColumnValue">
                                <span data-testid={"data-test-id-seller-total-forest-reduction-calculation"} aria-hidden>{calculatedTotalForestBaseReductionMaxLimit}</span>
                            </div>
                            <div className="rowElementHeader">
                                <ToolTipAction
                                    text={
                                        <>
                                            <Typography sx={visuallyHidden}>{t("disposalProfitForestReduction")}</Typography>
                                            <Typography>{t("disposalProfitForestReductionTooltip")}</Typography>
                                            <Typography sx={visuallyHidden}>{calculatedTotalForestBaseReductionMaxLimit ? t("calculatedValue").replace("{$value}", calculatedTotalForestBaseReductionMaxLimit) : t("noCalculatedValue")}</Typography>
                                        </>
                                    }
                                />
                                <span aria-hidden>{t("disposalProfitForestReduction")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}