import { useEffect, useState } from "react";

interface IDataInputMask {
    text: string;
    offset: number;
}

interface IProps {
    inputValue: string;
    onupdate: (value: string) => void;
    index: number;
    ariaDescription?: string;
    ariaLabel: string;
    id?: string;
    onInvalid?: (event: any) => void;
}

export function MaskedInput(props: IProps) {
    const [dataInputMask, setDataInputMask] = useState<IDataInputMask>({ text: "1/2", offset: 2 });

    useEffect(() => {
        onInputListener(props.inputValue)
    }, [props.inputValue])

    function onInputListener(maskInputValue: string): void {
        const repeatCount: number = maskInputValue.length;

        if (repeatCount === 0) {
            setDataInputMask({ text: "1/2", offset: 2 });
            return;
        }

        let textPadding = `${"\u00a0".repeat(repeatCount)}`;
        if (!maskInputValue.includes("/")) {
            setDataInputMask({ text: `${textPadding}/2`, offset: (repeatCount + 5) * 2 });
        } else if (maskInputValue.slice(-1) === "/") {
            setDataInputMask({ text: `${textPadding}2`, offset: (repeatCount + 3) * 2 });
        } else {
            setDataInputMask({ text: "", offset: 0 });
        }
    }

    return (
        <span id={`${props.id}-estatePercentageOwnership`} className="estatePercentageOwnership" data-input-mask={dataInputMask.text} style={{ "--data-input-mask-offset": `${dataInputMask.offset}px` } as React.CSSProperties}>
            <input aria-label={props.ariaLabel} id={props.id} data-testid={`data-test-id-estate-percentage-ownership-${props.index}`} aria-description={props.ariaDescription} type="text" pattern="^[1-9]*[\/]?[1-9]\d*$" onChange={(event) => props.onupdate(event.target.value)} value={props.inputValue} required onInvalid={props.onInvalid} />
        </span>

    )
}