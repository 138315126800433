import { useTranslation } from 'react-i18next';
import '../css/Header.css';
import { useAccessibilityScreenReaderNotifier } from '../hooks/useAccessibilityScreenReaderNotifier';
import { ComponentUtils } from '../utils/ComponentUtils';

export function Header() {
    const { t, i18n } = useTranslation();
    const notifyScreenReader = useAccessibilityScreenReaderNotifier(7000);

    function changeLanguage(event: any): void {
        const langCode = event.target.value;
        document.documentElement.lang = langCode;
        i18n.changeLanguage(langCode);

        notifyScreenReader(langCode === "fi" ? t("languageChangedToFinnish") : t("languageChangedToSweedish"));
    }

    return (
        <header>
            <div className="header">
                <div className="headerLogoAndLanuage">
                    {ComponentUtils.getMetsakeskusHeaderLogoWithLanguage(i18n.language)}
                    <span>
                        <label htmlFor='language'>
                            {t("languageTitle")}
                        </label>
                        <select onChange={changeLanguage} id="language">
                            <option value={"fi"}>Suomi</option>
                            <option value={"sv"}>Svenska</option>
                        </select>
                    </span>
                </div>
            </div >
        </header>
    )
}

// These are moved to own component because of accessibility WCAG criterions
export function HeaderFormFillInstructions() {
    const { t } = useTranslation();
    return (
        <div className='headerInfo'>
            <div className='headerInfoSection'>
                <h1>{t('headerTitle')}</h1>
                <div>
                    <span>{t('headerInfoColumn1')}</span>
                </div>
                <div>
                    <span>{t('headerInfoColumn2')}</span>
                </div>
                <div>
                    <span>{t('headerInfoColumn3')}</span>
                </div>
                <div>
                    <span>{t('headerInfoColumn4')}</span>
                </div>
                <div>
                    <span>{t('headerInfoColumn5')}</span>
                </div>
            </div>
        </div>
    )
}