
import "../css/Tooltip.css";
import { ClickAwayListener, IconButton } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  text: React.ReactNode;
}

export function ToolTipAction(props: IProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const ariaProps = { "aria-label": t('tooltipAriaLabel') }

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  function renderInfoTooltip() {
    if (!props.text) { return null; }

    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          onClose={handleTooltipClose}
          open={open}
          disableHoverListener
          disableTouchListener
          role="button"
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'var(--dark-green)',
                '& .MuiTooltip-arrow': {
                  color: 'var(--dark-green)',
                },
              },
            },
          }} title={props.text} placement={'top'}>
          <IconButton {...ariaProps} onClick={handleTooltipOpen} aria-expanded={open}>
            <InfoOutlinedIcon className="tooltipIcon" />
          </IconButton>
        </Tooltip>
      </ClickAwayListener>
    )
  }

  return (
    <div className={`tooltipContainer`}>
      {renderInfoTooltip()}
    </div >
  );
}