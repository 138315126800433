import { ChangeEvent, useEffect, useState } from "react"
import { EstateUtils } from "../../utils/EstateUtils";

interface IProps {
    value: number | null;
    onUpdateState: (value: string) => void;
    ariaDescription?: string;
    ariaLabel: string;
    precision?: number;
    stateKey?: string;
    onBlur?: (event: any, key?: string, callback?: (callbackValue: string) => void) => void;
    onFocus?: (event: any) => void;
    onInvalid?: (event: any) => void;
    dataTestid?: string;
    id?: string;
    formatByDefault?: boolean;
}

export function NumberInputField(props: IProps) {
    const [numberValue, setNumberValue] = useState<string>(props.value !== null ? processInput(`${props.value}`, props.formatByDefault || false) : "");

    useEffect(() => {
        props.onUpdateState(numberValue);
    }, [numberValue])

    function processInput(inputValue: string, shouldRound: boolean = false): string {
        let processedInput = inputValue.replace(",", ".")

        if (shouldRound && props.precision) {
            try {
                const numberConversation = EstateUtils.parseNumber(processedInput);
                const roundedValue = numberConversation !== null ? numberConversation.toFixed(props.precision) : null;
                if (roundedValue) {
                    processedInput = roundedValue;
                }
            } catch (error) {
                console.log("error", error);
            }
        }

        return processedInput;
    }

    function onChangeHandler(event: ChangeEvent<any>): void {
        const processedInput: string = processInput((event.target.value));

        // @ts-ignore
        if (isNaN(processedInput)) { return; }

        setNumberValue(processedInput);
    }

    function numberInputOnBlur(event: any): void {
        if (props.onBlur) {
            props.onBlur(event, props.stateKey, (callbackValue: string) => {
                setNumberValue(processInput(callbackValue, true));
            })
        } else {
            setNumberValue(processInput((numberValue), true))
        }
    }

    return (
        <input
            aria-label={props.ariaLabel}
            data-testid={props.dataTestid}
            type="text"
            inputMode="decimal"
            aria-description={props.ariaDescription}
            value={numberValue}
            onChange={onChangeHandler}
            onBlur={numberInputOnBlur}
            onInvalid={props.onInvalid}
            onFocus={props.onFocus}
            id={props.id}
            required
        />
    )
}