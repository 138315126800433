import { useRef, useState, useEffect } from 'react';
import { EstateCostInfoComponent } from './EstateCostInfoComponent';
import { EstateInfoComponent } from './EstateInfoComponent';
import { ToolTipAction } from './Tooltip';
import { useTranslation } from 'react-i18next';
import { ForestReductionComponent } from './ForestReductionComponent';
import { Calculations } from './Calculations';
import { ICalculator } from '../interfaces/ICalculator';
import { initialEstateInfo } from '../models/MEstateInfo';
import { IEstate, IEstateDamages, IEstateInfo, IForestReduction } from '../interfaces/IEstate';
import { EstateUtils } from '../utils/EstateUtils';
import { initialForestReduction } from '../models/MEstateForestReduction';
import { initialEstate } from '../models/MEstate';
import { NumberInputField } from './Inputs/NumberInputField';
import { Header, HeaderFormFillInstructions } from './Header';
import { Footer } from './Footer';
import { ComponentUtils } from '../utils/ComponentUtils';
import { usePdfReportGenerator } from '../hooks/usePdfReportGenerator';
import '../css/Calculator.css';
import { useAccessibilityScreenReaderNotifier } from '../hooks/useAccessibilityScreenReaderNotifier';
import { Typography } from '@mui/material';

enum EFormEvent {
    calculate = "calculateEventSubmitter",
    generatePdf = "pdfGeneratorEventSubmitter",
}

export function Calculator() {
    const { t } = useTranslation();
    const estateCostInfoComponentRef = useRef<{ notifyEstateUpdates: (updatedEstates: IEstate[]) => void }>();
    const calculationsComponentRef = useRef<{ calculate: (calculatorState: ICalculator) => void, getIsCalculated: () => boolean }>();
    const notifyScreenReader = useAccessibilityScreenReaderNotifier(7000);

    const [state, setState] = useState<ICalculator>({
        estateInfo: initialEstateInfo,
        estateCostInfo: [initialEstate],
        estateDamages: { damages: null },
        estateForestReduction: initialForestReduction
    });
    const [isCalculated, setIsCalculated] = useState<boolean>(false);
    const [generatePdf] = usePdfReportGenerator();

    useEffect(() => {
        if (isCalculated) {
            setIsCalculated(false);
        }
    }, [state])

    function onUpdateState(updatedPartialState: IEstateInfo | IEstate[] | IEstateDamages | IForestReduction, key: keyof ICalculator): void {
        let updatedState = { ...state };
        (updatedState[key] as IEstateInfo | IEstate[] | IEstateDamages | IForestReduction) = updatedPartialState;

        setState(updatedState);
    }

    function onNotifyEstateUpdate(updatedEstateCostInfo: IEstate[]): void {
        estateCostInfoComponentRef.current?.notifyEstateUpdates(updatedEstateCostInfo);
    }

    function handleFormProcessing(event: any) {
        event.preventDefault();

        try {
            const eventSubmitter = (event.nativeEvent.submitter as HTMLElement).id;
            if (eventSubmitter === EFormEvent.calculate) {
                calculationsComponentRef.current?.calculate(state);
                if (!isCalculated) {
                    setIsCalculated(true);
                }
                notifyScreenReader(t("calculationDoneAccessibilityNotification"));
            } else if (eventSubmitter === EFormEvent.generatePdf && isCalculated) {
                generateReport();
            }
        } catch (error) {

        }
    }

    function removeInvalidMessages() {
        document.querySelectorAll(`div.inputInvalidErrorMessage`).forEach(invalidMessageElement => invalidMessageElement.remove());

        document.querySelectorAll(`div.rowContainInvalidInput`).forEach(invalidMessageElement => invalidMessageElement.classList.remove("rowContainInvalidInput"));
    }

    async function generateReport() {
        generatePdf(state);
    }

    return (
        <>
            <Header />
            <main>
                <HeaderFormFillInstructions />
                <form id="calculator" onKeyPress={(event) => {
                    // Prevent enter to submit the value
                    if (event.key === "Enter") {
                        event.preventDefault();
                        event.stopPropagation();
                    }
                }} onSubmit={handleFormProcessing}
                    onInvalid={() => {
                        const formObject = document.getElementById("calculator");
                        if (formObject) {
                            formObject.className = "formValidated";
                        }
                    }}
                >
                    <div className="sectionContainer">
                        <div className="sectionHeading">
                            <span>{t("allFieldsRequired")}</span>
                        </div>
                    </div>
                    <EstateInfoComponent onUpdateState={onUpdateState} />
                    <hr className='calculatorHr' />
                    <EstateCostInfoComponent
                        ref={estateCostInfoComponentRef}
                        onUpdateState={onUpdateState}
                        estateUnclaimedDepreciationImprovementCosts={state.estateInfo.estateUnclaimedDepreciationImprovementCosts || null}
                        estateSellingExpenses={state.estateInfo.estateSellingExpenses || null}
                        estateSellingPrice={state.estateInfo.estateSellingPrice}
                        isNaturalPerson={!state.estateInfo.nonNaturalPerson}
                        estateConveyanceDate={state.estateInfo.estateConveyanceDate}
                    />
                    <hr className='calculatorHr' />
                    <div className="sectionContainer">
                        <div className="sectionHeading">
                            <h2>{t("compensationInfoHeader")}</h2>
                        </div>
                        <div className="sectionContainer">
                            <div className="section">
                                <div className="row">
                                    <div className="rowElement">
                                        <div className="rowInputElement">
                                            <NumberInputField
                                                ariaLabel={t("compensation")}
                                                dataTestid='data-test-id-estate-damages'
                                                precision={2}
                                                value={state.estateDamages.damages}
                                                onUpdateState={(value) => onUpdateState({ damages: EstateUtils.parseNumber(value) }, "estateDamages")}
                                                id="compensation"
                                                onInvalid={() => ComponentUtils.formInputFieldInvalidNotification("compensation", t("invalidCompensationMessage"))}
                                            />
                                        </div>
                                        <div className="rowElementHeader">
                                            <ToolTipAction
                                                text={
                                                    <Typography>{t("compensationTooltip")}</Typography>
                                                }
                                            />
                                            <label aria-hidden htmlFor='compensation'>{t("compensation")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='calculatorHr' />
                    <ForestReductionComponent onUpdateState={onUpdateState} onNotifyEstateUpdate={onNotifyEstateUpdate} estateCostInfo={state.estateCostInfo} />
                    <div className="sectionContainer sectionContainerButton">
                        <div className="sectionContainer">
                            <div className="section">
                                <div className="row">
                                    <button data-testid='data-test-id-calculate-button' onClick={removeInvalidMessages} id={EFormEvent.calculate} className="baseButton pull-right" type="submit" form="calculator" value="Submit" >{t("calculateButton")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='calculatorHr' />
                    <Calculations ref={calculationsComponentRef} />
                    <div className="sectionContainer sectionContainerButton">
                        <div className="sectionContainer">
                            <div className="section">
                                <div className="row">
                                    <div className='pull-right'>
                                        {(calculationsComponentRef.current?.getIsCalculated() && !isCalculated) && (
                                            <span className='preventPrintingMessage'>{t("calculatorFieldsEditedAfterCalculation")}</span>
                                        )}
                                        <button disabled={!isCalculated} id={EFormEvent.generatePdf} className={`baseButton`} type="submit">{t("printReportButton")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </main>
            <Footer />
        </>
    )
}