import { useEffect } from "react";


export function useAccessibilityScreenReaderNotifier(timeout?: number) {

    useEffect(() => {
        const alerts = document.getElementById("accessibiltyLanguageAlert");
        if (!alerts) {
            const alertElement = document.createElement("div");
            const alertTextElement = document.createElement("span");

            alertElement.id = "accessibiltyLanguageAlert";
            alertElement.className = "sr-only";
            alertElement.role = "alert";
            alertElement.ariaLive = "assertive";

            alertTextElement.className = "hiddenAccessibilityAlertMessage";

            alertElement.appendChild(alertTextElement);
            document.body.appendChild(alertElement);
        }
    }, [])

    function notifyScreenReader(notification: string) {
        // Notify screen reader that the language has changed
        // Hide the notification from document after 4s
        setTimeout(() => {
            if (!(document.getElementById("accessibiltyLanguageAlert")?.firstChild as HTMLElement)?.classList.contains("hiddenAccessibilityAlertMessage")) {
                (document.getElementById("accessibiltyLanguageAlert")?.firstChild as HTMLElement)?.classList.add("hiddenAccessibilityAlertMessage");
                (document.getElementById("accessibiltyLanguageAlert")?.firstChild as HTMLElement).textContent = ``;
            }
        }, timeout || 4000);
        // Show the screen reader notification
        (document.getElementById("accessibiltyLanguageAlert")?.firstChild as HTMLElement)?.classList.remove("hiddenAccessibilityAlertMessage");
        (document.getElementById("accessibiltyLanguageAlert")?.firstChild as HTMLElement).textContent = notification;
    }

    return notifyScreenReader;
}